import '@babylonjs/loaders/glTF';
import '@babylonjs/core/Animations/animatable';
import '@babylonjs/core/Lights/Shadows/shadowGeneratorSceneComponent';
import '@babylonjs/core/Loading/loadingScreen';
import '@babylonjs/core/Materials/standardMaterial';
import '@babylonjs/core/Meshes/meshBuilder';
import '@/modules/sceneWrapper/assets/models/violin.glb';
import '@/modules/sceneWrapper/assets/textures/violin_tex_AO.jpg';
import '@/modules/sceneWrapper/assets/textures/violin_tex_color.jpg';
import '@/modules/sceneWrapper/assets/textures/violin_tex_metalness.jpg';
import '@/modules/sceneWrapper/assets/textures/violin_tex_normal.jpg';
import '@/modules/sceneWrapper/assets/textures/violin_tex_roughness.jpg';
import {Engine}                              from '@babylonjs/core/Engines/engine';
import {Color4}                              from '@babylonjs/core/Maths/math';
import {Scene}                               from '@babylonjs/core/scene';
import pointerPos                            from '@/modules/pointer/scripts/pointerPos';
import {pointerPosGet}                       from '@/modules/pointer/scripts/pointerPosGet';
import pointerPosPrevious                    from '@/modules/pointer/scripts/pointerPosPrevious';
import CameraWrapper                         from '@/modules/sceneWrapper/scripts/CameraWrapper';
import Cursor                                from '@/modules/sceneWrapper/scripts/Cursor';
import DblclickCursor                        from '@/modules/sceneWrapper/scripts/DblclickCursor';
import {panDistanceGet, panIs, touchEventIs} from '@/modules/sceneWrapper/scripts/eventRelated';
import frameEnded                            from '@/modules/sceneWrapper/scripts/frameEnded';
import LightsWrapper                         from '@/modules/sceneWrapper/scripts/LightsWrapper';
import Label                                 from '@/modules/sceneWrapper/scripts/Label';
import ModelWrapper                          from '@/modules/sceneWrapper/scripts/ModelWrapper';
import mouseExists                           from '@/modules/sceneWrapper/scripts/mouseExists';
import Axes                                  from '@/modules/sceneWrapper/types/Axes';
import section                               from '@/modules/section/scripts/section';
import resizeDelayAdd                        from '@/scripts/resizeDelayAdd';

declare global
{
    interface Window
    {
        /** Making all scene wrappers global. */
        sceneWrappers: SceneWrapper[];
    }
}

/** Class manipulating a scene wrapper of any model. */
export default class SceneWrapper
{
    /** Determines whether the intro animation has ended. */
    public animationIntroEnded: boolean = false;
    /** The wrapper of the active camera of the scene. */
    public readonly cameraWrapper: CameraWrapper;
    /** HTML Canvas Element used for rendering the scene. */
    public readonly canvas: HTMLCanvasElement;
    /** Container of the HTML Canvas Element. */
    private readonly canvasContainer: HTMLDivElement;
    /** HTML Element used for overlaying the canvas element to prevent default unwanted behavior related to pointer events. */
    public readonly canvasCurtain: HTMLDivElement;
    /** Class containing the custom cursor following the actual cursor.  */
    public readonly cursor: Cursor;
    /** Class containing the custom double-click cursor used on mobile devices for animation. */
    private readonly dblclickCursor: DblclickCursor;
    /**
     *  @param dblclickChecker Checks whether the click is a double or a single click.
     *  @param dblclickChecker.clickCount Counts the number of time the user clicked in a row.
     *  @param dblclickChecker.lastClickTime Timestamp of the last click.
     */
    private readonly dblclickChecker: { clickCount: number, lastClickTime: number } = Object.seal({clickCount: 0, lastClickTime: 0});
    /** Resets the click counter, prevents double click if clicks are separated by more than 500ms. */
    private dblclickTimeout: number = null;
    /** Babylon.js engine of the scene. */
    private readonly engine: Engine;
    /** Time of the last frame, dependent on the fps limit. */
    private readonly frameTimeLast: { pointermoveOn: number } = Object.seal({
        pointermoveOn: 0
    });
    /** Frames per second limit. */
    public static readonly fps: number = 60;
    /** Duration of a single frame, dependent on the fps limit. */
    public static readonly frameDuration: number = 1000 / SceneWrapper.fps;
    /** Delay of the user interactions of the model. */
    public static readonly interactionDelay: number = 100;
    /** Image of the label behind the scene. */
    private readonly label: Label;
    /** The wrapper of the lights of the scene. */
    public readonly lightsWrapper: LightsWrapper;
    /** The wrapper of the model of the scene. */
    public readonly modelWrapper: ModelWrapper;
    /** Determines whether the user is panning. */
    public panning: boolean = false;
    /** Distance of the touches of a pan. */
    public panstartDistance: number = 0;
    /** Babylon.js scene in which the model is placed. */
    public readonly scene: Scene;
    /** Stores the sceneLoaded property, don't use directly, use getter/setter without the _ instead. */
    private _sceneLoaded: boolean = false;
    /** The event target of the touchstart event. */
    private touchstartElement: HTMLElement;

    /** Creates a scene wrapper, should be run after loading of the DOM tree. */
    constructor()
    {
        this.canvasContainer = document.querySelector(`#violin-canvas-container`);
        this.canvas = this.canvasContainer.querySelector(`#violin-canvas`);
        this.canvasCurtain = this.canvasContainer.querySelector(`#violin-canvas-curtain`);
        this.cursor = new Cursor(this.canvasContainer.querySelector(`.cursor`));
        this.dblclickCursor = new DblclickCursor(this.canvasContainer.querySelector(`.dblclick-cursor`));

        this.engine = new Engine(this.canvas, true, {
            premultipliedAlpha: false
        }, true);

        this.scene = new Scene(this.engine);
        /** Sets the transparent background of the scene. */
        this.scene.clearColor = new Color4(1, 1, 1, 0);
        /** Disables the scene default event listeners.. */
        this.scene.detachControl();
        /** Correctly loads the textures. */
        this.scene.useRightHandedSystem = true;
        this.cameraWrapper = new CameraWrapper(this);
        this.lightsWrapper = new LightsWrapper(this);
        this.modelWrapper = new ModelWrapper(this);

        this.label = new Label(this);

        this.engine.runRenderLoop(() =>
        {
            this.scene.render();
        });

        /** After loading assets, hides loading and loads the scene. */
        window.addEventListener(`sceneLoaded`, async () =>
        {
            this.sceneLoaded = true;
            this.modelWrapper.rotate();

            await this.animationIntroBegin().catch(console.error);

            if (!mouseExists()/* && !Cookies.get(`visited`)*/)
            {
                await this.animationDblclickCursorBegin().catch(console.error);
                await this.animationCursorBegin().catch(console.error);
            }

            this.animationIntroEnded = true;
            /* /!** Sets the cookie to check whether the user has visited the site before to prevent loading animations from multiple runs. *!/
             Cookies.set(`visited`, `true`);*/
        }, {once: true});

        window.addEventListener(`resize`, () =>
        {
            resizeDelayAdd(this.resizeOn.bind(this));
        });

        /** 1 at the end indicates, it is the second in the event listener order, followed by the method which updates pointer-related globals. */
        window.addEventListener(`touchstart1`, ({detail: event}: CustomEvent<TouchEvent>) =>
        {
            this.pointerdownOn(event);
        });
        window.addEventListener(`touchmove1`, ({detail: event}: CustomEvent<TouchEvent>) =>
        {
            this.pointermoveOn(event);
        });
        window.addEventListener(`touchend1`, ({detail: event}: CustomEvent<TouchEvent>) =>
        {
            this.pointerupOn(event);
        });

        if (mouseExists())
        {
            /** 1 at the end indicates, it is the second in the event listener order, followed by the method which updates pointer-related globals. */
            window.addEventListener(`mousedown1`, ({detail: event}: CustomEvent<MouseEvent>) => this.pointerdownOn(event));
            window.addEventListener(`mousemove1`, ({detail: event}: CustomEvent<MouseEvent>) => this.pointermoveOn(event));
            window.addEventListener(`mouseup1`, ({detail: event}: CustomEvent<MouseEvent>) => this.pointerupOn(event));
        }

        this.canvasCurtain.addEventListener(`click`, (event) => this.dblclickOn(event));

        window.addEventListener(`wheel`, (event) => this.wheelOn(event));

        window.addEventListener(`scroll`, (/*event*/) => this.scrollOn());

        window.addEventListener(`beforeunload`, (event) =>
        {
            this.scene.dispose();
            event = null;
        });
    }

    /** @description Begins the animation of the cursor. */
    private async animationCursorBegin(): Promise<boolean>
    {
        this.modelWrapper.animating = true;

        await this.cursor.show().catch(console.error);
        this.cursor.animate().catch(console.error);

        await this.modelWrapper.animationCursorBegin();

        await this.cursor.hide().catch(console.error);

        return true;
    }

    /** @description Begins the animation of the dblclick cursor.  */
    private async animationDblclickCursorBegin(): Promise<boolean>
    {
        this.modelWrapper.animating = true;

        await this.dblclickCursor.moveTo(`50%`);

        await this.dblclickCursor.dblclick();
        await this.cameraWrapper.zoomValueSetAsync(2);
        await this.dblclickCursor.dblclick();
        await this.cameraWrapper.zoomValueSetAsync(1);

        await this.dblclickCursor.hide();

        return true;
    }

    /** @description Begins the intro animation both for the desktop and mobile. */
    private async animationIntroBegin(): Promise<void>
    {
        await this.modelWrapper.animationIntroBegin();
    }

    /** @description Listener of the dblclick event. */
    private dblclickOn(event: MouseEvent | TouchEvent): void
    {
        if (!this.animationIntroEnded)
        {
            return;
        }

        event.preventDefault();

        /** Resets the double click checker. */
        const dblclickCheckerReset: () => void = () =>
        {
            this.dblclickChecker.lastClickTime = 0;
            this.dblclickChecker.clickCount = 0;
            this.dblclickTimeout = null;
        };

        if (this.dblclickChecker.clickCount === 1 && new Date().getTime() - this.dblclickChecker.lastClickTime < 500)
        {
            /** Destined camera zoom value of a double click. */
            const dblclickZoomSize: number = 2;

            this.cameraWrapper.zoomValue = this.cameraWrapper.zoomValue === 1 ? dblclickZoomSize : 1;
            window.clearTimeout(this.dblclickTimeout);
            dblclickCheckerReset();
            return;
        }

        this.dblclickChecker.lastClickTime = new Date().getTime();
        this.dblclickChecker.clickCount += 1;
        this.dblclickTimeout = window.setTimeout(dblclickCheckerReset.bind(this), 500);
    }

    /** @description Determine whether the mouse is over the canvas. */
    private mouseoverIs({x, y}: Pick<Axes, 'x' | 'y'>): boolean
    {
        return document.elementFromPoint(x, y) === this.canvasCurtain || !mouseExists();
    }

    /** @description Listener of the start of a pan. */
    private panstartOn(event: TouchEvent): void
    {
        this.panstartDistance = panDistanceGet(event);
        this.cameraWrapper.panstartOn();
        this.panning = true;
    }

    /** @description Listener of a pointer move during a pan. */
    private panmoveOn(event: TouchEvent): void
    {
        this.cameraWrapper.panmoveOn(event);
    }

    /** @description Listener of the end of a pan. */
    private panendOn(): void
    {
        this.panning = false;
    }

    /** @description Listener of the mousedown and the touchstart event. */
    private pointerdownOn(event: MouseEvent | TouchEvent): void
    {
        const {x, y} = pointerPosGet(event);

        /** If the mouse is not over the canvas element, grabbing-related events are not triggered. */
        if (!this.animationIntroEnded || event.target !== this.canvasCurtain || !this.mouseoverIs({x, y}) || !this.modelWrapper.loaded)
        {
            return;
        }

        if (touchEventIs(event))
        {
            this.touchstartElement = event.target as HTMLElement;
        }

        this.cameraWrapper.pointerdownOn(event);

        if (panIs(event))
        {
            this.panstartOn(event as TouchEvent);
        }

        this.modelWrapper.pointerdownOn();

        if (touchEventIs(event))
        {
            section.scrollEnabled = !this.cameraWrapper.zoomed;
        }
    };

    /** @description Listener of the mousemove and the touchmove event. */
    private pointermoveOn(event: MouseEvent | TouchEvent): void
    {
        if (!pointerPosPrevious.x && pointerPos.x)
        {
            return;
        }

        if (!frameEnded(this.frameTimeLast.pointermoveOn))
        {
            return;
        }

        this.label.pointermoveOn();

        this.frameTimeLast.pointermoveOn = Date.now();

        if (panIs(event))
        {
            this.panmoveOn(event as TouchEvent);
            return;
        }

        this.cameraWrapper.pointermoveOn();
        this.modelWrapper.pointermoveOn(event);
    };

    /** @description Listener of the mouseup and the touchend event. */
    private pointerupOn(event: MouseEvent | TouchEvent): void
    {
        this.cameraWrapper.pointerupOn();

        if (event.target === this.touchstartElement)
        {
            this.touchstartElement.click();
        }

        this.touchstartElement = null;

        this.panendOn();
        this.modelWrapper.pointerupOn();
    };

    /** @description Listener of the resize event. */
    private resizeOn(): void
    {
        this.engine.resize();
        this.label.resizeOn();
    }

    /** @description Determines whether the scene is loaded. */
    private get sceneLoaded(): boolean
    {
        return this._sceneLoaded;
    }

    private set sceneLoaded(value)
    {
        this._sceneLoaded = value;

        if (value)
        {
            this.canvasContainer.classList.remove(`loading`);
        }
    }

    /** @description Listener of the scroll event. */
    private scrollOn(): void
    {
        this.modelWrapper.scrollOn();
    }

    /** @description Listener of the wheel event. */
    private wheelOn(event: WheelEvent): void
    {
        this.cameraWrapper.wheelOn(event);
    }
}
