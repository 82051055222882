/** Determines whether a touch event is a pan or not. */
export function panIs(event: MouseEvent | TouchEvent): boolean
{
    return 'touches' in event && event.touches.length === 2;
}

/** Calculates the distance of both touches which triggered the pan. */
export function panDistanceGet(event: TouchEvent): number
{
    const [{clientX: x0}, {clientX: x1}] = event.touches;

    return Math.abs(x0 - x1);
}

/** Determines whether the event is triggered by a touch or not. */
export function touchEventIs(event: TouchEvent | MouseEvent): boolean
{
    return Boolean(event.type.match(/^touch/));
}
