import Carousel        from './Carousel';
import section         from '@/modules/section/scripts/section';
import urlGet          from '@/modules/url/scripts/urlGet';
import urlUpdate       from '@/modules/url/scripts/urlUpdate';
import Axes            from '@/modules/sceneWrapper/types/Axes';
import pointerdownPos  from '@/modules/pointer/scripts/pointerdownPos';
import {ProjectProps}  from '@/modules/project/types/ProjectProps';
import freezeNested    from '@/scripts/freezeNested';
import {pointerPosGet} from '@/modules/pointer/scripts/pointerPosGet';

/** Singleton containing the class manipulating the projects. */
class Project
{
    /** @param active The active project.
     *  @param active.index The index of the project relative to the carousel, used as a key in the props object.
     *  @param active.name The name of the project.
     *  @param active.pathname The URL pathname of the project.
     *  */
    public active: ProjectProps;
    /** HTML Element containing the arrow which moves the slider to the left. */
    private arrowLeft: HTMLAnchorElement;
    /** HTML Element containing the arrow which moves the slider to the right. */
    private arrowRight: HTMLAnchorElement;
    /** Duration of the slide animation. */
    private readonly animationDuration: number = 7000;
    /** @param blocked Determines which functionality is blocked.
     *  @param blocked.all Determines whether all functionality is blocked.
     *  @param blocked.scrolling Determines whether functionality triggered by scrolling is blocked.
     *  */
    private readonly blocked: { [s in 'all' | 'scrolling']: boolean } = Object.seal({
        all: false,
        scrolling: false
    });
    /** The time which muss pass between two scroll-triggered slides. */
    private readonly blockScrollingDuration: number = 2500;
    /** List of all timeouts which block some functionality. */
    private blockTimeouts: number[] = [];
    /** Carousel containing the home slides of projects. */
    private readonly carousel: Carousel;
    /** The timeout which triggers an automatic slide. */
    private intervalTimeout: number;
    /** Properties of all project. */
    private readonly props: DeepReadonly<Omit<ProjectProps, 'index'>[]> = freezeNested([
        {name: `violin`, pathname: `violin`},
        {name: `architects`, pathname: `architects`},
        {name: `fitness`, pathname: `fitness`}
    ]);

    constructor()
    {
        this.carousel = new Carousel();
    }

    /**
     * @description Sets the active project by the index of the active circle.
     * @param circleActiveNewIndex The index of the active circle.
     */
    public activeSet(circleActiveNewIndex: number): void
    {
        /** Prevents a new slide animation shortly after a previous slide animation. */
        if (this.blocked.all)
        {
            return;
        }

        /** Nullifies the timeout which awaits an animation slides, prevents stacking of the animations. */
        this.clearIntervalTimeout();

        /** The previous index of the active project. */
        const activeIndexOld: number = this.active?.index;

        const activeIndexNew: number = circleActiveNewIndex >= 0 ? circleActiveNewIndex % this.length : this.length - 1;
        this.active = {...this.props[activeIndexNew], index: activeIndexNew};

        urlUpdate();

        if (typeof activeIndexOld !== `undefined`)
        {
            this.carousel.go({activeIndexOld, activeIndex: this.active.index, circleActiveNewIndex: circleActiveNewIndex});
        }

        this.hrefUpdate();

        if (section.active.name === `home`)
        {
            this.intervalTimeout = window.setTimeout(() =>
            {
                this.activeSet(this.active.index + 1);
            }, this.animationDuration);
        }

        document.querySelector<HTMLDivElement>(`.project-detail-content.active`)?.classList.remove(`active`);
        document.querySelector<HTMLDivElement>(`.project-detail-content[data-project=${this.active.pathname}]`)?.classList.add(`active`);
    }

    /**
     * @description Sets the active project by the URL.
     * */
    private activeUpdateByUrl(): void
    {
        /** The index of the new project. */
        const activeNew: number = Math.max(this.props.findIndex((prop) =>
        {
            return prop.pathname === window.location.pathname.split(`/`)[1];
        }), 0);

        this.activeSet(activeNew);
        this.carousel.jump(activeNew);
    }

    /**
     * @description Listens to a click on an arrow.
     * @param event Event object.
     * @param arrowType The type of the arrow, determines the shift of the carousel.
     * */
    private arrowClickOn(event: MouseEvent, arrowType: 'left' | 'right'): void
    {
        /** Prevents the sectionIndex reload. */
        event.preventDefault();
        this.activeSet(this.active.index + (arrowType === `left` ? -1 : 1));
    }

    /** @description Blocks the carousel.
     * @param duration Duration of the block in milliseconds.
     * */
    public block(duration: number): void
    {
        this.blockTimeouts.forEach(window.clearTimeout);
        this.blockTimeouts = [];

        this.blocked.all = true;
        this.blocked.scrolling = true;

        this.blockTimeouts.push(
            window.setTimeout(() =>
            {
                this.blocked.all = false;
            }, duration),
            window.setTimeout(() =>
            {
                this.blocked.scrolling = false;
            }, Math.max(this.blockScrollingDuration, duration))
        );
    }

    /**
     * @description Clears the timeout awaiting the next automatic slide animation.
     * */
    public clearIntervalTimeout(): void
    {
        /** If the interval timeout is not set, returns. */
        if (!this.intervalTimeout)
        {
            return;
        }

        window.clearTimeout(this.intervalTimeout);
        this.intervalTimeout = null;
        this.carousel.clearIntervalTimeout();
    }

    /**
     * Updates the href attributes of the arrow HTML elements to correspond with the project name, the arrow points to.
     * */
    private hrefUpdate(): void
    {
        /** Gets the pathname by the project index. */
        const pathnameGet: (projectIndex: number) => string = (projectIndex) =>
        {
            return this.props[projectIndex >= 0 ? projectIndex % this.props.length : this.props.length - 1].pathname;
        };

        this.arrowLeft.href = urlGet({projectPathname: pathnameGet(this.active.index - 1)});
        this.arrowRight.href = urlGet({projectPathname: pathnameGet(this.active.index + 1)});
        document.querySelector<HTMLAnchorElement>(`.navbar-brand`).href = `/${this.active.pathname}`;
    }

    /**
     * @description Number of projects.
     * */
    public get length(): number
    {
        return this.props.length;
    }

    /**
     * @description Mounts the project, run only once, required for making the class working, should be run after loading of the DOM tree.
     */
    public mount(): void
    {
        const arrowWrap: HTMLElement = document.querySelector(`footer`);

        this.arrowLeft = arrowWrap.querySelector<HTMLAnchorElement>(`.arrow-left`);
        this.arrowRight = arrowWrap.querySelector<HTMLAnchorElement>(`.arrow-right`);

        this.carousel.mount();
        this.activeUpdateByUrl();
        window.addEventListener(`wheel`, (event) => this.wheelOn(event), {passive: false});
        window.addEventListener(`touchend`, (event) => this.touchendOn(event), {passive: false});

        this.arrowLeft.addEventListener(`click`, (event: MouseEvent) =>
        {
            this.arrowClickOn(event, `left`);
        });
        this.arrowRight.addEventListener(`click`, (event: MouseEvent) =>
        {
            this.arrowClickOn(event, `right`);
        });

        arrowWrap.querySelectorAll<HTMLElement>(`.one-box`).forEach((element) =>
        {
            element.addEventListener(`click`, (event: Merge<MouseEvent, {currentTarget: HTMLElement}>) =>
            {
                /** Index of the clicked number. */
                const targetIndex: number = [...event.currentTarget.parentNode.children].indexOf(event.currentTarget);

                event.preventDefault();
                this.activeSet(targetIndex - document.querySelectorAll<HTMLDivElement>(`.one-box.clone`).length / 2);
            });
        });
    }

    /**
     * @description Listener of the touchend event.
     */
    private touchendOn(event: TouchEvent): void
    {
        if (section.active.name === `home`)
        {
            const {x, y} = pointerPosGet(event);

            event.preventDefault();

            const element: HTMLButtonElement = document.elementFromPoint(x, y) as HTMLButtonElement;

            /** If the touch ends on the same position as it started, it might be considered a click. */
            if (pointerdownPos.x === x && pointerdownPos.y === y && element.click)
            {
                element.click();
            }

            /** The difference of the current pointer position and the position of the pointer during the touchstart event. */
            const coordinatesDiff: Pick<Axes, 'x' | 'y'> = {
                x: pointerdownPos.x - x,
                y: pointerdownPos.y - y
            };

            /** If the user swipes right, the next project is slided. */
            if (((coordinatesDiff.x >= 0 && coordinatesDiff.y >= -50) || (coordinatesDiff.x >= -50 && coordinatesDiff.y >= 0)) && (coordinatesDiff.x + coordinatesDiff.y >= 50))
            {
                this.activeSet(this.active.index + 1);
            }
            /** If the user swipes right, the previous project is slided. */
            else if (((coordinatesDiff.x <= 0 && coordinatesDiff.y <= 50) || (coordinatesDiff.x <= 50 && coordinatesDiff.y <= 0)) && (coordinatesDiff.x + coordinatesDiff.y <= -50))
            {
                this.activeSet(this.active.index - 1);
            }
        }
    }

    /**
     * @description Listener of the wheel event.
     */
    private wheelOn(event: WheelEvent): void
    {
        if (section.active.name === `home`)
        {
            const wheelDirection: number = Math.sign(event.deltaY);

            event.preventDefault();

            if (this.blocked.scrolling)
            {
                return;
            }

            this.activeSet(this.active.index + wheelDirection);
        }
    }
}

const project: Project = new Project();

export default project;
