import project        from '../../project/scripts/project';
import urlUpdate      from '../../url/scripts/urlUpdate';
import NumericRange   from 'numeric-range';
import {touchEventIs} from '@/modules/sceneWrapper/scripts/eventRelated';
import {SectionProps} from '@/modules/section/types/SectionProps';
import {SectionName}  from '@/modules/section/types/SectionName';
import resizeDelayAdd from '@/scripts/resizeDelayAdd';
import freezeNested from '@/scripts/freezeNested';
import chromeIs     from '@/modules/platforms/scripts/chromeIs';

/** Singleton containing the class manipulating the sections. */
class Section
{
    /** @param active The active section.
     *  @param active.name The name of the section, used as a key in the props property.
     *  @param active.pathname The URL pathname of the section.
     *  @param active.scroll Determines whether scrolling of the window is enabled when the section is active.
     *  */
    public active: SectionProps;
    /** Determines whether the section HTML Elements have been aligned at least once. */
    public aligned: boolean = false;
    /** The section HTML elements. */
    private elements: NodeListOf<HTMLDivElement>;
    /** Timeout used to prevent scroll stacking. */
    private scrollBlockTimeout: number = null;
    /** Stores the scrollEnabled property, don't use directly, use getter/setter without the _ instead. */
    private _scrollEnabled: boolean = true;
    /** Desired window scroll sectionIndex, might differ from the current window scroll sectionIndex. */
    private scrollTopSectionActiveIndex: number = Math.round(window.pageYOffset / window.innerHeight);
    /** Properties related to all sections.
     * @param props
     * @param props.pathname The pathname which occurs in the URL when the section is active.
     * @param props.scroll Determines whether scrolling of the window is enabled when the section is active.
     *  */
    private readonly props: DeepReadonly<{
        [s in SectionName]: Omit<SectionProps, 'name'>
    }> = freezeNested({
        'contact': {pathname: `kontakt`, scroll: true},
        'home': {pathname: ``, scroll: false},
        'project-detail': {pathname: `detail-projektu`, scroll: true}
    });
    /** Timeout used to prevent wheel stacking. */
    private wheelBlockDuration: number = null;

    /**
     * @description Returns the scroll sectionIndex of the active section.
     */
    private activePageIndexGet(): number
    {
        return [...this.elements].indexOf(document.querySelector<HTMLDivElement>(`#${this.active.name}`));
    }

    /**
     * @description Sets the active section by the section name.
     * @param sectionArgs
     * @param sectionArgs.name The name of the desired active section.
     */
    private activeSet({name: sectionActiveNew}: {name: SectionName}): void
    {
        /** The name of the current active section. */
        const sectionActiveOld: SectionName = this.active?.name;

        /** In case of unchanged active section, ignore the rest of the method. */
        if (sectionActiveOld === sectionActiveNew)
        {
            return;
        }

        /** The name property is assigned here to prevent duplicate declaring in the props object. */
        this.active = {...this.props[sectionActiveNew], name: sectionActiveNew};

        this.elements.forEach((element) =>
        {
            element.classList[element.id === this.active.name ? `add` : `remove`](`active`);
        });

        /**
         * Toggles the detail class by the active section, required for the specific behavior of the home section.
         */
        if (this.active.name === `home`)
        {
            document.body.classList.remove(`detail`);
        }
        else
        {
            document.body.classList.add(`detail`);
        }

        Section.pathnameUpdate();

        /**
         * Turns off and on the carousel by the active section, if the active section is not home, the carousel is turned off and vice versa.
         */
        if (sectionActiveOld && sectionActiveOld !== `home` && sectionActiveNew === `home`)
        {
            project.activeSet(project.active.index);
        }
        else if (sectionActiveNew !== `home`)
        {
            project.clearIntervalTimeout();
        }

        this.scrollTo({sectionIndex: this.activePageIndexGet()});
    }

    /**
     * @description Sets the active section the section pathname.
     * @param pathname The pathname of the desired active section.
     */
    private activeSetByPathname(pathname: string): void
    {
        this.activeSet({
            name: Object.entries(this.props).find(([, sectionOther]) =>
            {
                return pathname === sectionOther.pathname;
            })?.[0] as SectionName
        });
    }

    /**
     * @description Updates the active section by the window scroll sectionIndex.
     */
    private activeUpdateByScrollTop(): void
    {
        if (this.active.scroll)
        {
            const sectionActiveIndex: number = this.scrollTopSectionActiveIndex;

            /** Result of the iteration of HTML section elements to determine the new active section. */
            const name: SectionName = sectionActiveIndex === 0 ? `project-detail` : this.elements[sectionActiveIndex].id as SectionName;

            this.activeSet({name});
        }
    };

    /**
     * @description Updates the active section by the current URL.
     */
    private activeUpdateByUrl(): void
    {
        this.activeSet({
            name: Object.entries(this.props).filter(([, sectionOther]) =>
            {
                return sectionOther.pathname;
            }).find(([, sectionOther]) =>
            {
                return window.location.pathname.includes(`/${sectionOther.pathname}`);
            })?.[0] as SectionName || `home`
        });
    }

    /**
     * @description Align the elements by the current sectionIndex scroll sectionIndex.
     */
    private elementsScrollAlign(): void
    {
        this.elements.forEach((element, elementIndex) =>
        {
            /** Sets the top of a section HTML Element. */
            const topSet: () => void = () =>
            {
                const shift: number = Math.sign(elementIndex - this.scrollTopSectionActiveIndex);

                element.style.top = `calc(${(shift * 100)}vh + ${shift * 100}px)`;
            };

            if (this.aligned)
            {
                element.style.transitionDuration = `468ms`;
                /** Delay the setting of sectionIndex to ensure the transition is set. */
                window.setTimeout(() => topSet(), 0);
            }
            else
            {
                topSet();
            }

            window.setTimeout(() =>
            {
                element.style.transitionDuration = `0ms`;
                this.aligned = true;
            }, 468);
        });
    }

    /**
     * @description Mounts the section, run only once, required for making the class working, should be run after loading of the DOM tree.
     */
    public mount(): void
    {
        this.elements = document.querySelectorAll<HTMLDivElement>(`section`);
        this.activeUpdateByUrl();
        this.elementsScrollAlign();

        this.navbarBrandClickOn = this.navbarBrandClickOn.bind(this);
        this.resizeOn = this.resizeOn.bind(this);
        this.scrollOn = this.scrollOn.bind(this);
        this.wheelOn = this.wheelOn.bind(this);

        window.addEventListener(`resize`, () =>
        {
            resizeDelayAdd(this.resizeOn);
        });

        if (chromeIs())
        {
            this.scrollEnabled = false;

            /** Prevents auto scrolling at the page load. */
            setTimeout(() =>
            {
                this.scrollEnabled = true;
                window.addEventListener(`scroll`, this.scrollOn, {passive: false});
                window.addEventListener(`wheel`, this.wheelOn, {passive: false});
            }, 1000);
        }
        else
        {
            window.addEventListener(`scroll`, this.scrollOn, {passive: false});
            window.addEventListener(`wheel`, this.wheelOn, {passive: false});
        }

        window.addEventListener(`touchmove`, this.wheelOn, {passive: false});
        /** If the logo is clicked, returns to the home section. */
        document.querySelector<HTMLAnchorElement>(`.navbar-brand`).addEventListener(`click`, this.navbarBrandClickOn);
        /** Redirects to the detail of the active project. */
        document.querySelectorAll<HTMLAnchorElement>(`.project-image-container, .project-text-heading`).forEach((element) =>
        {
            element.addEventListener(`click`, (event: Merge<MouseEvent, {currentTarget: HTMLAnchorElement}>) =>
            {
                event.preventDefault();

                if (!this.scrollEnabled)
                {
                    return;
                }

                if (this.active.name === `project-detail`)
                {
                    this.scrollTo({sectionIndex: 1});
                }
                else
                {
                    this.activeSetByPathname(event.currentTarget.getAttribute(`href`));
                }
            });
        });
    }

    /**
     * @description Listener of the click on a .navbar-brand HTML element.
     */
    private navbarBrandClickOn(event: MouseEvent): void
    {
        event.preventDefault();
        this.activeSet({name: `home`});
    }

    /**
     * @description Updates the current URL pathname.
     */
    private static pathnameUpdate(): void
    {
        urlUpdate();
    }

    /**
     * @description Listener of the resize event.
     */
    private resizeOn(): void
    {
        window.clearTimeout(this.scrollBlockTimeout);
        this.scrollBlockTimeout = window.setTimeout(() =>
        {
            this.scrollBlockTimeout = null;
        }, 200);
        window.scrollTo(0, window.innerHeight * this.scrollTopSectionActiveIndex);
    }

    /** Determines whether scrolling of the window is enabled */
    public get scrollEnabled(): boolean
    {
        return this._scrollEnabled;
    }

    public set scrollEnabled(value)
    {
        this._scrollEnabled = value;
    }

    /**
     * @description Listener of the scroll event.
     */
    private scrollOn(event: Event): void
    {
        if (!this.active.scroll)
        {
            return;
        }

        /** Prevents scrolling when a model is zoomed. */
        if (!this.scrollEnabled || this.scrollBlockTimeout)
        {
            return;
        }

        const activeElement: HTMLDivElement = this.elements[this.scrollTopSectionActiveIndex];

        activeElement.style.top = `${(((window.innerHeight * this.scrollTopSectionActiveIndex) - window.pageYOffset) / window.innerHeight) * .25 * 100}vh`;
        this.scrollTo({sectionIndex: Math.round(window.pageYOffset / window.innerHeight)});
        this.activeUpdateByScrollTop();
    }

    /**
     * @description Scrolls to any window scroll sectionIndex.
     * @param scrollArgs
     * @param scrollArgs.sectionIndex Determines the desired scroll sectionIndex of the window.
     */
    private scrollTo({sectionIndex}: {sectionIndex: number}): void
    {
        /** Incorporated sectionIndex value into the bounds of the body scroll height. */
        const pageReal: number = new NumericRange(0, Math.round((document.body.scrollHeight - window.innerHeight) / window.innerHeight)).incorporate(sectionIndex);

        if (this.scrollTopSectionActiveIndex === pageReal)
        {
            return;
        }

        this.scrollTopSectionActiveIndex = pageReal;
        this.elementsScrollAlign();

        this.scrollBlockTimeout = window.setTimeout(() =>
        {
            this.scrollBlockTimeout = null;
            window.scrollTo(0, window.innerHeight * pageReal);
        }, 468);
    }

    /**
     * @description Listener of the wheel event.
     */
    private wheelOn(event: WheelEvent | TouchEvent)
    {
        if (!this.scrollEnabled || !this.active.scroll || this.scrollBlockTimeout)
        {
            event.preventDefault();
            return;
        }

        /** On mobile devices scroll event is not triggered. */
        if (touchEventIs(event))
        {
            this.scrollOn(event);
        }

        window.clearTimeout(this.wheelBlockDuration);
        this.wheelBlockDuration = window.setTimeout(() =>
        {
            this.wheelBlockDuration = null;
            window.scrollTo({behavior: `smooth`, left: 0, top: window.innerHeight * this.scrollTopSectionActiveIndex});
        }, 200);
    }
}

const section: Section = new Section();

export default section;
