import Numbers        from './Numbers';
import project        from './project';
import resizeDelayAdd from '@/scripts/resizeDelayAdd';

/** Carousel containing the home slides of projects. */
export default class Carousel
{
    /** HTML Element of the carousel containing the slides. */
    private element: HTMLDivElement;
    /** Stores the jumping property, don't use directly, use getter/setter without the _ instead. */
    private _jumping: boolean = false;
    private numbers: Numbers;
    /** HTML Elements of the slides. */
    private slides: NodeListOf<HTMLDivElement>;
    /** Timeout determining when the next slide should become active. */
    private timeout: number;

    constructor()
    {
        this.numbers = new Numbers();
    }

    /** Clears the timeout and jumps into the slide defined by the parameter.
     * @param elementIndex The index of the slide the carousel should go to. */
    private clear(elementIndex: number): void
    {
        window.clearTimeout(this.timeout);

        if (this.timeout)
        {
            this.jump(elementIndex);
        }

        this.timeout = null;
    }

    /** Clears the timeout waiting for the next slide. */
    public clearIntervalTimeout(): void
    {
        [...this.slides].filter((slide) =>
        {
            return slide.classList.contains(`clone`);
        }).forEach((slide) =>
        {
            slide.classList.remove(`active`);
        });
        this.numbers.clearIntervalTimeout();
    }

    /** Clones the slides to prevent jumping of the carousel when switching from the last to the first slide and vice versa. */
    private clone(): void
    {
        /** Clones of the slides. */
        const slidesClone: HTMLDivElement[] = [...this.slides].map((element) =>
        {
            return element.cloneNode(true) as HTMLDivElement;
        });

        /** Slides which will be prepended. */
        const prependSlides: HTMLDivElement[] = slidesClone.slice(-1).map((element) =>
        {
            return element.cloneNode(true) as HTMLDivElement;
        });

        prependSlides.map((prependSlide) =>
        {
            prependSlide.classList.add(`clone`);
            return prependSlide;
        }).reverse().forEach((prependSlide) =>
        {
            this.element.insertBefore(prependSlide, this.element.children[0]);
        });

        /** Slides which will be appended. */
        const appendSlides: HTMLDivElement[] = slidesClone.slice(0, 1).map((element) =>
        {
            return element.cloneNode(true) as HTMLDivElement;
        });

        appendSlides.map((appendSlide) =>
        {
            appendSlide.classList.add(`clone`);
            return appendSlide;
        }).forEach(($prependSlide) =>
        {
            this.element.appendChild($prependSlide);
        });

        this.elementUpdate();
    }

    // private declone(): void
    // {
    //     [...this.slides].filter((element) =>
    //     {
    //         return element.classList.contains(`clone`)
    //     }).forEach((element) =>
    //     {
    //         element.delete()
    //     });
    //     this.elementUpdate();
    // }

    /** Update the DOM-related properties. */
    private elementUpdate(): void
    {
        this.element = document.querySelector(`.project-wrap`);
        this.slides = document.querySelectorAll(`.project`);
        this.element.style.width = `${(this.slides.length * window.innerWidth)}px`;
    }

    /** Go to the slide defined by the active project.
     * @param args
     * @param args.activeIndexOld The previous active slide.
     * @param args.activeIndex The current active slide.
     * @param args.circleActiveNewIndex The index of the desired active circle.
     * */
    public go({activeIndexOld, activeIndex, circleActiveNewIndex}: { activeIndexOld: number, activeIndex: number, circleActiveNewIndex: number }): void
    {
        this.clear(activeIndexOld);

        if (Math.abs(circleActiveNewIndex - activeIndexOld) === 1 && (activeIndexOld === project.length - 1 && activeIndex === 0 || activeIndexOld === 0 && activeIndex === project.length - 1))
        {
            this.move(activeIndex === 0 ? project.length : -1);
            project.block(1000);

            this.timeout = window.setTimeout(() =>
            {
                this.jump(activeIndex);
            }, 1000);
        }
        else
        {
            this.jumping = false;
            /** Block sliding during the ongoing slide animation. */
            this.move(activeIndex);
            project.block(500);
        }
    }

    /** Jumps without the animation from the last to the first slide or vice versa.
     * @param elementIndex The index of the slide the carousel should jump to. */
    public jump(elementIndex: number): void
    {
        this.positionSet({elementIndex, transition: false});
        this.numbers.jump(elementIndex);
    }

    /** Determines whether the slide animation has duration or not. */
    private get jumping(): boolean
    {
        return this._jumping;
    }

    private set jumping(jumpingValue)
    {
        if (jumpingValue === this.jumping)
        {
            return;
        }

        this._jumping = jumpingValue;

        this.element.classList.remove(jumpingValue ? `no-jump` : `jump`);
        this.element.classList.add(jumpingValue ? `jump` : `no-jump`);
    }

    /**
     * @description Mounts the carousel, run only once, required for making the class working, should be run after loading of the DOM tree.
     */
    public mount(): void
    {
        this.elementUpdate();
        this.clone();
        this.numbers.mount();

        window.addEventListener(`resize`, () =>
        {
            resizeDelayAdd(this.resizeOn.bind(this));
        });
    }

    /**
     * @description Moves the carousel to the desired position.
     * @param elementIndex The index of the slide the carousel should move to.
     */
    private move(elementIndex: number): void
    {
        this.positionSet({elementIndex});
        this.numbers.move(elementIndex);
    }

    /**
     * @description Sets the position of the carousel.
     * @param args
     * @param args.elementIndex The index of the slide the carousel should move to.
     * @param args.transition Determines whether the change of the position should be animated or not.
     */
    private positionSet({elementIndex, transition = true}: { elementIndex: number, transition?: boolean }): void
    {
        this.slides.forEach((element, elementOtherIndex) =>
        {
            if (elementOtherIndex === (elementIndex + 1))
            {
                element.classList.add(`active`);
            }
            else
            {
                element.classList.remove(`active`);
            }
        });

        this.jumping = !transition;

        this.element.style.transform = `translateX(${-((elementIndex + 1) * 100)}vw)`;
    }

    /** @description Listener of the resize event. */
    private resizeOn(): void
    {
        this.elementUpdate();
    }
}
