import SceneWrapper       from '@/modules/sceneWrapper/scripts/SceneWrapper';
import {Mesh}             from '@babylonjs/core/Meshes/mesh';
import {StandardMaterial} from '@babylonjs/core/Materials/standardMaterial';
import {Plane, Vector3}   from '@babylonjs/core/Maths/math';
import {DynamicTexture}   from '@babylonjs/core/Materials/Textures/dynamicTexture';
import mouseExists        from '@/modules/sceneWrapper/scripts/mouseExists';
import mousePos           from '@/modules/pointer/scripts/mousePos';
import iosIs              from '@/modules/platforms/scripts/iosIs';
import '@/modules/sceneWrapper/assets/images/render-txt-detaility.svg';
import '@/modules/sceneWrapper/assets/images/render-txt-detaility-mobile.svg';

/** Class containing the the label behind the model.  */
export default class Label
{
    /** Image of the label behind the scene. */
    private readonly label: Mesh;
    /** Class manipulating a scene wrapper of any model. */
    private readonly sceneWrapper: SceneWrapper;
    /** Determines whether the desktop version of the texture is loaded. */
    private textureDesktopIs: boolean;

    constructor(sceneWrapper: SceneWrapper)
    {
        this.sceneWrapper = sceneWrapper;

        this.label = Mesh.CreatePlane(`label`, 9, this.sceneWrapper.scene);
        this.positionSync();

        this.materialLoad();
    }

    /** @description Loads the material. */
    private materialLoad(): void
    {
        if (this.textureDesktopIs === (window.innerWidth > 767))
        {
            return;
        }

        this.textureDesktopIs = window.innerWidth > 767;

        /** Material the texture will be placed in. */
        const materialPlane: StandardMaterial = new StandardMaterial(`texturePlane`, this.sceneWrapper.scene);
        materialPlane.backFaceCulling = false;

        /** Loads the image of the label. */
        const imageOnload: () => void = () =>
        {
            /** Plane the texture will be rendered on. */
            const sourcePlane: Plane = new Plane(1, 1, 1, -5);
            sourcePlane.normalize();

            /** Texture of the label. */
            const materialPlaneTexture: DynamicTexture = new DynamicTexture(`label`, {
                height: image.width * 4,
                sourcePlane,
                sideOrientation: Mesh.DOUBLESIDE,
                width: image.width * 4
            }, this.sceneWrapper.scene, true);

            /** Canvas 2D context of the texture. */
            const context: CanvasRenderingContext2D = materialPlaneTexture.getContext();

            context.fillStyle = `#ffffff`;
            context.fillRect(0, 0, image.width * 4, image.width * 4);
            context.drawImage(image, 0, 0, image.width * (3 + Number(!iosIs())), image.height * (3 + Number(!iosIs())));
            materialPlane.diffuseTexture = materialPlaneTexture;
            materialPlaneTexture.update();

            this.label.material = materialPlane;
        };

        const image: HTMLImageElement = new Image();
        image.onload = imageOnload;

        if (window.innerWidth > 767)
        {
            image.src = `/assets/images/render-txt-detaility.dcfa330236f589fd385d692c450fac48.svg`;
        }
        else
        {
            image.src = `/assets/images/render-txt-detaility-mobile.3073c3f9470aa855b03693f092dbd954.svg`;
        }


        /** If image is cached, don't wait for the onload event, on some browsers it doesn't fire in that case. */
        if (image.height > 0)
        {
            imageOnload();
        }
    }

    /** @description Listener of the mousemove and touchmove events. */
    public pointermoveOn(): void
    {
        if (mouseExists())
        {
            this.positionSync();
        }
    }

    /** @description Syncs the position of the label with the viewport resolution and mouse position */
    private positionSync(): void
    {
        const scaling: number = (window.innerWidth / 1920) * (944 / ((window.innerHeight * .86) + .14 * 944));
        const mouseRelated: number = Number(mouseExists()) * (((mousePos.x / window.innerWidth) - .5) * .06);

        this.label.scaling = new Vector3(scaling, scaling, scaling);
        this.label.position = new Vector3(
            .5 * scaling * (1 + mouseRelated) * (1 + (.5 * Number(iosIs()))),
            -3 + (4.8 * (1 - scaling)),
            -5
        );
    }

    /** @description Listener of the resize event. */
    public resizeOn(): void
    {
        this.positionSync();
        this.materialLoad();
    }
}
