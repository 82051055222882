import {AbstractMesh}                                   from '@babylonjs/core/Meshes/abstractMesh';
import {Vector3}                                        from '@babylonjs/core/Maths/math';
import {PBRMaterial}                                    from '@babylonjs/core/Materials/PBR/pbrMaterial';
import {AssetsManager, MeshAssetTask, TextureAssetTask} from '@babylonjs/core/Misc/assetsManager';
import SceneWrapper                                     from '@/modules/sceneWrapper/scripts/SceneWrapper';
import pointerPos                                       from '@/modules/pointer/scripts/pointerPos';
import Axes                                             from '@/modules/sceneWrapper/types/Axes';
import mousePos                        from '@/modules/pointer/scripts/mousePos';
import mouseExists                     from '@/modules/sceneWrapper/scripts/mouseExists';
import NumericRange                    from 'numeric-range';
import {Animation}                     from '@babylonjs/core/Animations/animation';
import {IAnimationKey}                 from '@babylonjs/core/Animations/animationKey';
import {EasingFunction, QuadraticEase} from '@babylonjs/core/Animations/easing';
import Loading                         from '@/modules/sceneWrapper/scripts/Loading';
import {pointerPosGet}                 from '@/modules/pointer/scripts/pointerPosGet';
import pointerdownPos                  from '@/modules/pointer/scripts/pointerdownPos';
import {touchEventIs}                  from '@/modules/sceneWrapper/scripts/eventRelated';
import pointerPosPrevious              from '@/modules/pointer/scripts/pointerPosPrevious';

/** The wrapper of the model of the scene. */
export default class ModelWrapper
{
    /**  Stores the animating property, don't use directly, use getter/setter without the _ instead. */
    private _animating: boolean = false;
    /** The assets manager loading the model assets. */
    private readonly assetsManager: AssetsManager;
    /** Stores the grabbing property, don't use directly, use getter/setter without the _ instead. */
    private _grabbing: boolean = false;
    /** Direction of the grabbing movement of the model, -1 indicates right to left, 1 indicates left to right. */
    private grabbingDirection: -1 | 0 | 1 = 0;
    /** Last starting position of the model grabbing before the change of direction. */
    private grabbingLastStartingPos: Pick<Axes, 'x' | 'y'> = {...pointerPos};
    /** Last starting time of the model grabbing before the change of direction. */
    private grabbingLastStartingTime: number = 0;
    /** Object handling the loading of the model. */
    private readonly loading: Loading;
    /** The default properties of model meshes. */
    private readonly meshProperties: Partial<Merge<AbstractMesh, { material: PBRMaterial }>> = {};
    /** Meshes of the model. */
    private readonly model: AbstractMesh[] = [];
    /** The position of mouse in the time of ending model rotation. */
    private mouseStartingPos: Pick<Axes, 'x' | 'y'> = {...mousePos};
    /** The default rotation of the model independent of any user interactions. */
    private readonly rotationDefault: Vector3 = new Vector3(0, .6781, 0);
    /** The scaling size of the model. */
    public static readonly scalingSize: number = Math.cbrt((.4 ** 3) * .9 * .75);
    /** Class manipulating a scene wrapper of any model. */
    private readonly sceneWrapper: SceneWrapper;

    constructor(sceneWrapper: SceneWrapper)
    {
        this.sceneWrapper = sceneWrapper;
        this.loading = new Loading(this.sceneWrapper.canvas);

        /** Default values of the imported meshes. */
        this.meshProperties = {
            material: new PBRMaterial(`pbrMaterial`, this.sceneWrapper.scene),
            position: new Vector3(-.00525, 0, 0),
            rotation: new Vector3(0, .6781, 0),
            receiveShadows: true,
            rotationQuaternion: null,
            scaling: new Vector3(ModelWrapper.scalingSize, ModelWrapper.scalingSize, ModelWrapper.scalingSize)
        };

        this.assetsManager = new AssetsManager(this.sceneWrapper.scene);
        this.assetsManager.useDefaultLoadingScreen = false;

        /** The path of the assets directory. */
        const dirname: string = `/modules/sceneWrapper/assets`;

        /** Paths of the model textures. */
        const texturePaths: { [s: string]: string } = {
            albedo: `/assets/textures/violin_tex_color.5a5fd143e1b9014eed2023f2eff8721c.jpg`,
            bump: `/assets/textures/violin_tex_normal.d6c9d0e64b4f72adae16096ef240cc0b.jpg`,
            metallic: `/assets/textures/violin_tex_metalness.63904e59f9e6a580c7444a468f13fb43.jpg`,
            ambient: `/assets/textures/violin_tex_AO.6b3a55798d390b25672290c85d11821b.jpg`,
            microSurface: `/assets/textures/violin_tex_roughness.8a6d0abdb3322a7f76a2841d81284c56.jpg`
        };

        Object.entries(texturePaths).forEach(([textureName, texturePath]) =>
        {
            const textureTask: TextureAssetTask = this.assetsManager.addTextureTask(textureName, texturePath);

            textureTask.onSuccess = (task) =>
            {
                this.meshProperties.material[`${textureName}Texture`] = task.texture;
            };
        });

        const modelPath: string = `/assets/models/violin.9819ecd195bd382f693acf23c1a051e7.glb`;

        const modelTask: MeshAssetTask = this.assetsManager.addMeshTask(
            `model`,
            ``,
            `${modelPath.split(`/`).slice(0, -1).join(`/`)}/`,
            modelPath.split(`/`).slice(-1).join(`/`)
        );

        this.assetsManager.onProgress = (remainingCount, totalCount) =>
        {
            this.loading.sync(remainingCount, totalCount);
        };

        this.assetsManager.load();

        this.meshProperties.material.metallic = 1;
        this.meshProperties.material.roughness = .8;

        /** Values used for the initial animation of the model, not used after the animation. */
        const meshBeforeMountProperties: Partial<Merge<AbstractMesh, { material: PBRMaterial }>> = {
            rotation: this.meshProperties.rotation.add(new Vector3(0, 1, 0)),
            scaling: Vector3.Zero()
        };

        /** Imports the model. */
        modelTask.onSuccess = (model) =>
        {
            /** Assigns textures, scaling and default rotation of the model. */
            model.loadedMeshes.forEach((mesh) =>
            {
                Object.keys(this.meshProperties).forEach((propertyName) =>
                {
                    mesh[propertyName] = meshBeforeMountProperties[propertyName] ?? this.meshProperties[propertyName];
                    this.model.push(mesh);
                });

                this.sceneWrapper.lightsWrapper.shadowCreate(mesh);
            });
        };

        // this.model.push(
        //     Mesh.CreateBox(`violin`, ModelWrapper.scalingSize, this.sceneWrapper.scene)
        // );
        //
        // const material: StandardMaterial = new StandardMaterial(`violinMaterial`, this.sceneWrapper.scene);
        //
        // material.diffuseColor = new Color3(1, 0, 0);
        //
        // this.model[0].material = material;
        // this.model[0].rotation = this.rotationDefault;
        // this.sceneWrapper.animationIntroEnded = true;
    }

    public async animationCursorBegin(): Promise<void>
    {
        const rotationAnimation: Animation = new Animation(
            `introAnimation`,
            `rotation`,
            SceneWrapper.fps,
            Animation.ANIMATIONTYPE_VECTOR3,
            Animation.ANIMATIONLOOPMODE_RELATIVE
        );

        const animationFrameDuration: number = SceneWrapper.fps;

        const rotationKeys: Merge<IAnimationKey, { value: Vector3 }>[] = [
            {frame: 0, value: this.model[0].rotation},
            {frame: animationFrameDuration / 4, value: this.model[0].rotation.add(new Vector3(0, -Math.PI / 12, 0))},
            {frame: 3 * (animationFrameDuration / 4), value: this.model[0].rotation.add(new Vector3(0, Math.PI / 12, 0))},
            {frame: animationFrameDuration, value: this.model[0].rotation}
        ];

        rotationAnimation.setKeys(rotationKeys);

        const easingFunction: QuadraticEase = new QuadraticEase();

        easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
        rotationAnimation.setEasingFunction(easingFunction);

        await Promise.all(this.model.map(async (mesh) =>
        {
            const animatable = this.sceneWrapper.scene.beginDirectAnimation(mesh, [rotationAnimation], 0, animationFrameDuration, false);

            animatable.onAnimationEnd = () =>
            {
                this.animating = false;
            };

            return animatable.waitAsync().catch(console.error);
        }));
    }

    public async animationIntroBegin(): Promise<void>
    {
        this.animating = true;

        const scalingAnimation: Animation = new Animation(
            `scalingAnimation`,
            `scaling`,
            SceneWrapper.fps,
            Animation.ANIMATIONTYPE_VECTOR3,
            Animation.ANIMATIONLOOPMODE_RELATIVE
        );

        const rotationAnimation: Animation = new Animation(
            `rotationAnimation`,
            `rotation`,
            SceneWrapper.fps,
            Animation.ANIMATIONTYPE_VECTOR3,
            Animation.ANIMATIONLOOPMODE_RELATIVE
        );

        const animationFrameDuration: number = SceneWrapper.fps * 2;

        const scalingKeys: Merge<IAnimationKey, { value: Vector3 }>[] = [
            {frame: 0, value: Vector3.Zero()},
            {frame: animationFrameDuration, value: this.meshProperties.scaling}
        ];

        const rotationKeys: Merge<IAnimationKey, { value: Vector3 }>[] = [
            {frame: 0, value: this.model[0].rotation.add(new Vector3(0, Math.PI / 2, 0))},
            {frame: animationFrameDuration, value: this.meshProperties.rotation}
        ];

        scalingAnimation.setKeys(scalingKeys);
        rotationAnimation.setKeys(rotationKeys);

        const easingFunction: QuadraticEase = new QuadraticEase();

        easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
        scalingAnimation.setEasingFunction(easingFunction);
        rotationAnimation.setEasingFunction(easingFunction);

        await Promise.all(this.model.map(async (mesh) =>
        {
            const animatable = this.sceneWrapper.scene.beginDirectAnimation(mesh, [scalingAnimation, rotationAnimation], 0, animationFrameDuration, false);

            animatable.onAnimationEnd = () =>
            {
                this.animating = false;
            };

            return animatable.waitAsync().catch(console.error);
        }));
    }

    /** Determines whether the model is currently animating. */
    public get animating(): boolean
    {
        return this._animating;
    }

    public set animating(value)
    {
        this._animating = value;

        if (!value)
        {
            if (this.grabbing)
            {
                this.grabbingDirection = 0;
                this.grabbingLastStartingPos = {...pointerPos};
                this.grabbingLastStartingTime = Date.now();
            }

            /** Rotation related to the mouse position, 180°/screen width */
            const mousePosRelated: Pick<Axes, 'y'> = {
                y: Number(!this.grabbing && !this.animating) * (mousePos.x - this.mouseStartingPos.x) / (150 * this.sceneWrapper.cameraWrapper.zoomValue) * .015 * Number(
                    mouseExists())
            };

            this.rotationDefault.y = this.model[0].rotation.y - mousePosRelated.y;
            this.mouseStartingPos = {...mousePos};
        }
    }

    /** @description Determines whether the model is being grabbed.. */
    public get grabbing(): boolean
    {
        return this._grabbing;
    }

    public set grabbing(value: boolean)
    {
        if (value === this.grabbing)
        {
            return;
        }

        this._grabbing = value;
        document.body.classList[this._grabbing ? `add` : `remove`](`grabbing`);

        if (value)
        {
            this.model.forEach((mesh) =>
            {
                this.sceneWrapper.scene.stopAnimation(mesh, `rotationAnimation`);
            });
        }
        else
        {
            const pointerPosCopy: Pick<Axes, 'x' | 'y'> = {...pointerPos};

            const rotationDistance: number = pointerPosCopy.x - this.grabbingLastStartingPos.x;
            const rotationTime: number = Date.now() - this.grabbingLastStartingTime;
            const animationDuration: number = new NumericRange(750, 3000).incorporate(rotationTime * 2);

            window.setTimeout(() =>
            {
                this.animating = true;

                const rotationAnimation: Animation = new Animation(
                    `rotationAnimation`,
                    `rotation`,
                    SceneWrapper.fps,
                    Animation.ANIMATIONTYPE_VECTOR3,
                    Animation.ANIMATIONLOOPMODE_RELATIVE
                );

                const animationFrameDuration: number = Math.round(animationDuration / SceneWrapper.frameDuration);

                /** Block the animation in the case of small movements. */
                if (Math.abs(rotationDistance) < 50 || animationFrameDuration === 0)
                {
                    this.animating = false;
                    return;
                }

                this.rotationDefault.addInPlace(new Vector3(
                    0,
                    (rotationDistance / animationDuration) * .8,
                    0)
                );

                const rotationKeys: Merge<IAnimationKey, { value: Vector3 }>[] = [
                    {frame: 0, value: this.model[0].rotation},
                    {frame: animationFrameDuration, value: this.rotationGet()}
                ];

                rotationAnimation.setKeys(rotationKeys);

                const easingFunction: QuadraticEase = new QuadraticEase();

                easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEOUT);
                easingFunction.ease(.99);
                rotationAnimation.setEasingFunction(easingFunction);

                this.model.forEach((mesh) =>
                {
                    const animatable = this.sceneWrapper.scene.beginDirectAnimation(mesh, [rotationAnimation], 0, animationFrameDuration, false);

                    animatable.onAnimationEnd = () =>
                    {
                        this.animating = false;
                    };
                });
            }, SceneWrapper.interactionDelay);
        }
    }

    /** Determines whether the model assets are loaded. */
    public get loaded(): boolean
    {
        return Boolean(this.model.length);
    }

    public pointerdownOn(): void
    {
        this.grabbing = true;
    }

    public pointermoveOn(event: TouchEvent | MouseEvent): void
    {
        /** If the model is animating, don't calculate the grab distance. */
        if (this.animating)
        {
            return;
        }

        const {x, y} = pointerPosGet(event);

        /** Difference of the pointer position during the pointerdown-like event and current pointer position. */
        const diff: Pick<Axes, 'x' | 'y'> = {
            x: Math.abs(x - pointerdownPos.x),
            y: Math.abs(y - pointerdownPos.y)
        };

        if (touchEventIs(event))
        {
            if ((diff.x > diff.y) && (diff.x >= 20))
            {
                /** If the user swipes on the x-axis, grabbing-related events are triggered. */
                event.preventDefault();

                if (!this.grabbing)
                {
                    this.grabbing = true;
                    return;
                }
            }
            else
            {
                return;
            }
        }

        if (this.grabbing)
        {
            /** Difference of the x-coordinate of the previous and current pointer position. */
            const xDiffRelative: number = x - pointerPosPrevious.x;
            const modelGrabbingDirectionPrevious: 1 | 0 | -1 = this.grabbingDirection;

            if (xDiffRelative !== 0)
            {
                this.grabbingDirection = Math.sign(xDiffRelative) as 1 | -1;
            }

            if (modelGrabbingDirectionPrevious === 0 || this.grabbingDirection !== modelGrabbingDirectionPrevious)
            {
                this.grabbingLastStartingPos = {...pointerPos};
                this.grabbingLastStartingTime = Date.now();
            }

            if (window.innerWidth < 767)
            {
                /** Rotation related to the pointer position, 360°/screen width */
                this.rotationDefault.y += (xDiffRelative / window.innerWidth) * Math.PI * (3 - 0.0768) * .5;
            }
            else
            {
                /** Rotation related to the pointer position, 720°/screen width */
                this.rotationDefault.y += (xDiffRelative / window.innerWidth) * Math.PI * (3 - 0.0768);
            }
        }

        this.rotate();
    }

    public pointerupOn(): void
    {
        /** If the grabbing is not ongoing, turning off is not necessary. */
        if (!this.grabbing || this.sceneWrapper.cameraWrapper.zoomed)
        {
            return;
        }

        this.grabbing = false;
    }

    /** @description Rotate the model, should be the only way to rotate the model, direct manipulation of model rotation vector might lead to inconsistencies. */
    public rotate(): void
    {
        /** In case the model is not imported yet, rotating the model is not possible */
        if (!this.model.length || this.animating)
        {
            return;
        }

        const rotation: Vector3 = this.rotationGet();

        const rotate: () => void = () =>
        {
            this.model.forEach((mesh) =>
            {
                mesh.rotation = rotation;
            });
        };

        window.setTimeout(rotate.bind(this), SceneWrapper.interactionDelay);
    }

    /** @description Get the rotation the model should have, the current rotation might differ. */
    private rotationGet(): Vector3
    {
        // /** Shift of the y-axis based on the y position of the window. */
        // const scrollRelated: Pick<Axes, 'y'> = {
        //     y: ((Math.PI / 4.5) * ((window.pageYOffset - window.innerHeight) / window.innerHeight))
        // };

        /** Rotation related to the mouse position, 180°/screen width */
        const mousePosRelated: Pick<Axes, 'y'> = {
            y: Number(!this.grabbing && !this.animating) * (mousePos.x - this.mouseStartingPos.x) / (150 * this.sceneWrapper.cameraWrapper.zoomValue) * .015 * Number(mouseExists())
        };

        return new Vector3(
            this.rotationDefault.x,
            this.rotationDefault.y + mousePosRelated.y,
            this.rotationDefault.z
        );
    }

    /** @description Listener of both a touch and a mouse scroll. */
    public scrollOn(): void
    {
        if (window.scrollY > 0)
        {
            this.rotate();
        }
    }
}
