import sleep from '@/scripts/sleep';

/** Class containing the custom double-click cursor used on mobile devices for animation.  */
export default class DblclickCursor
{
    /** Element containing the cursor. */
    public element: HTMLDivElement;
    /** Stores the scale property, don't use directly, use getter/setter without the _ instead. */
    public _scale: number = 1;
    /** Stores the scaleDuration property, don't use directly, use getter/setter without the _ instead. */
    private _scaleDuration: number = 250;

    constructor(element: HTMLDivElement)
    {
        this.element = element;
    }

    /** Simulates a user click. */
    public async click(): Promise<void>
    {
        await this.scaleSetAsync(1.25);
        await this.scaleSetAsync(1);
    }

    /** Simulates a user dblclick. */
    public async dblclick(): Promise<void>
    {
        await this.click();
        await this.click();
    }

    /** Hides the HTML Element. */
    public async hide(): Promise<void>
    {
        this.scaleDuration = 250;

        await this.scaleSetAsync(0);
    }

    /** Moves the HTML Element to the center of the screen. */
    public async moveTo(left: string): Promise<void>
    {
        this.element.style.left = left;

        await sleep(750);
    }

    /** Sets the scale of the HTML Element. */
    private get scale()
    {
        return this._scale;
    }

    private set scale(value: number)
    {
        this._scale = value;

        this.element.style.transform = `scale(${value}) translateX(-50%)`;
    }

    /** Async handler of the scale setter to await the related transition. */
    private async scaleSetAsync(value: number): Promise<void>
    {
        this.scale = value;

        await sleep( 100);
    }

    /** The duration of the scale transition. */
    private get scaleDuration()
    {
        return this._scaleDuration;
    }

    private set scaleDuration(value)
    {
        this._scaleDuration = value;

        this.element.style.transitionDuration = `.25s, .25s, .25s, .25s, ${value}ms`;
    }
}
