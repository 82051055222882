import project from '../../project/scripts/project';
import section from '../../section/scripts/section';

/** @description Puts together an URL based on the project and section.
 *  @param arg
 *  @param arg.projectPathname The pathname of a project.
 *  @param arg.sectionPathname The pathname of a section.
 * */
export default function urlGet({projectPathname = project.active.pathname, sectionPathname = section.active.pathname}: { projectPathname?: string, sectionPathname?: string } = {})
{
    return `/${projectPathname}/${sectionPathname ?? ``}`;
}
