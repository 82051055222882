import '@/styles/main.scss';
import '@/proposals';
import section          from '@/modules/section/scripts/section';
import project          from '@/modules/project/scripts/project';
import smoothscroll     from 'smoothscroll-polyfill';
import mousePos         from '@/modules/pointer/scripts/mousePos';
import pointerEvents    from '@/modules/pointer/scripts/pointerEvents';
import pointerPosUpdate from '@/modules/pointer/scripts/pointerPosUpdate';
import mouseExists      from '@/modules/sceneWrapper/scripts/mouseExists';
import SceneWrapper   from '@/modules/sceneWrapper/scripts/SceneWrapper';
import iosIs          from '@/modules/platforms/scripts/iosIs';
import resizeDelayAdd from '@/scripts/resizeDelayAdd';
import {Plane}          from '@babylonjs/core/Maths/math';
import {DynamicTexture} from '@babylonjs/core/Materials/Textures/dynamicTexture';
import {Mesh}           from '@babylonjs/core/Meshes/mesh';

/** Uses the service workers to cache the files. */
if (process.env.NODE_ENV === `production` && `serviceWorker` in navigator)
{
    window.addEventListener(`load`, () =>
    {
        navigator.serviceWorker.register(`/service-worker.js`).catch((registrationError) =>
        {
            console.error(`SW registration failed: `, registrationError);
        });
    });

    /** Current version of the assets cache. */
    const assetCacheVersion: number = 4;
    /** Current name of the assets cache dependent on the current version. */
    const assetCacheName: string = `assets-${assetCacheVersion}`;

    self.addEventListener(`activate`, (event) =>
    {
        event.waitUntil(
            caches.keys().then((cacheNames) =>
            {
                return Promise.all(
                    cacheNames.map((cacheName) =>
                    {
                        if (cacheName !== assetCacheName)
                        {
                            return caches.delete(cacheName);
                        }
                    })
                );
            })
        );
    });
}

/** Allows smooth scroll-behavior on the devices which do not support it natively. */
smoothscroll.polyfill();

document.addEventListener(`DOMContentLoaded`, () =>
{
    /** Removes the CSS vh units inconsistencies caused by different implementation across mobile browsers. */
    const resizeOn: () => void = () =>
    {
        document.documentElement.style.setProperty(`--vh`, `${window.innerHeight * .01}px`);
    };

    resizeOn();

    window.addEventListener(`resize`, () =>
    {
        resizeDelayAdd(resizeOn);
    });

    /** Updates the current position of the mouse, triggered on any mouse-related event. */
    const mousePosUpdate: (event: MouseEvent) => void = (event) =>
    {
        mousePos.x = event.clientX;
        mousePos.y = event.clientY;
    };

    window.project = project;
    window.section = section;

    if (mouseExists())
    {
        window.addEventListener(`mousedown`, mousePosUpdate);
        window.addEventListener(`mousemove`, mousePosUpdate);
        window.addEventListener(`mouseup`, mousePosUpdate);
    }

    pointerEvents.forEach((pointerEvent) =>
    {
        window.addEventListener(pointerEvent, pointerPosUpdate);
    });

    section.mount();
    project.mount();

    /** List of all scene wrappers. */
    const sceneWrappers: SceneWrapper[] = [];

    sceneWrappers.push(new SceneWrapper());

    window.sceneWrappers = sceneWrappers;

    document.querySelectorAll<HTMLElement>(`.navbar .navbar-wrap .btn-nav`).forEach((element) =>
    {
        /** Ejects the menu */
        element.addEventListener(`click`, (event) =>
        {
            event.preventDefault();

            const mainMenu: HTMLDivElement = document.querySelector(`.main-menu`);
            const mainMenuComputedStyle: CSSStyleDeclaration = getComputedStyle(mainMenu);
            const fadeDuration: number = Number.parseFloat(mainMenuComputedStyle.getPropertyValue(`transition-duration`)) * 1000;

            if (mainMenuComputedStyle.getPropertyValue(`opacity`) === `0`)
            {
                mainMenu.style.display = `block`;

                window.setTimeout(() =>
                {
                    mainMenu.style.opacity = `1`;
                }, 0);
            }
            else
            {
                mainMenu.style.opacity = `0`;

                window.setTimeout(() =>
                {
                    mainMenu.style.display = `none`;
                }, fadeDuration);
            }
        });
    });

    /** Prevents the mouse middle button scrolling. */
    window.addEventListener(`mousedown`, (event) =>
    {
        if (event.button === 1)
        {
            event.preventDefault();
        }
    });

    /** Hides the whole-screen loading HTML element. */
    document.querySelector<HTMLDivElement>(`#loading`).style.display = `none`;
});
