import Axes           from '@/modules/sceneWrapper/types/Axes';
import {touchEventIs} from '@/modules/sceneWrapper/scripts/eventRelated';

/** Gets the pointer position from a pointer-related event. */
export function pointerPosGet(event: MouseEvent | TouchEvent): Pick<Axes, 'x' | 'y'>
{
    const {clientX: x, clientY: y} = touchEventIs(event)
        ? ((event as TouchEvent)[event.type === `touchend` ? `changedTouches` : `touches`])[0] ?? {clientX: 0, clientY: 0}
        : event as MouseEvent;

    return {x, y};
}
