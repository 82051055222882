import mousePos     from '@/modules/pointer/scripts/mousePos';
import SceneWrapper from '@/modules/sceneWrapper/scripts/SceneWrapper';
import mouseExists from '@/modules/sceneWrapper/scripts/mouseExists';
import sleep       from '@/scripts/sleep';

/** Class containing the custom cursor following the actual cursor.  */
export default class Cursor
{
    /** Element containing the cursor. */
    public element: HTMLDivElement;
    /** Determines whether the model contained in the scene is loaded. */
    private modelLoaded: boolean = false;
    /** Stores the scale property, don't use directly, use getter/setter without the _ instead. */
    private _scale: number = 1;
    /** Stores the scaleDuration property, don't use directly, use getter/setter without the _ instead. */
    private _scaleDuration: number = 250;
    /** End of the cursor move. */
    private timeEnd: number = 0;
    /** Determines whether the cursor is moving. */
    private timeStartTimeout: number = null;
    /** Start of the cursor move. */
    private timeStart: number = 0;

    constructor(element: HTMLDivElement)
    {
        this.element = element;

        window.addEventListener(`sceneLoaded`, () =>
        {
            this.sceneLoadedOn();
        }, {once: true});

        /** If any hovering device is connected, start the intro HTML Element animation. */
        if (mouseExists())
        {
            this.scaleSetAsync(0).catch(console.error);
            this.scaleDuration = 2000;

            window.addEventListener(`mousemove1`, () =>
            {
                this.mousemoveOn();
            });
        }
    }

    /** @description Moves the HTML Element on the mobile devices. */
    public async animate(): Promise<void>
    {
        this.element.style.animation = `1s cursor-mobile-animation ease-in-out`;

        await sleep(1000);
    }

    /** @description Shows the HTML Element. */
    private elementShow(): void
    {
        if (this.modelLoaded && this.scale === 0 && this.timeStart)
        {
            this.scale = 1;
            window.setTimeout(() =>
            {
                this.scaleDuration = 250;
            }, this.scaleDuration);
        }
    }

    /** @description Hides the HTML Element. */
    public async hide(): Promise<void>
    {
        await this.scaleSetAsync(0);
    }

    /** @description Sync the cursor the position CSS property with the mouse position. */
    private mousemoveOn(): void
    {
        if (!this.timeStartTimeout)
        {
            this.timeStart = Date.now();
        }

        window.clearTimeout(this.timeStartTimeout);
        this.timeStartTimeout = null;
        this.timeEnd = 0;
        this.timeStartTimeout = window.setTimeout(() =>
        {
            this.elementShow();
            window.clearTimeout(this.timeStartTimeout);
            this.timeStartTimeout = null;
            this.timeEnd = Date.now();
        }, SceneWrapper.interactionDelay);

        window.setTimeout(() =>
        {
            this.element.style.left = `${mousePos.x + 30}px`;
            this.element.style.top = `${mousePos.y - 50}px`;
        }, Math.min(Date.now() - this.timeStart, SceneWrapper.interactionDelay * 2) + (this.timeEnd ? (Date.now() - this.timeEnd) : 0));
    }

    /** Determines the scale property of the HTML Element. */
    private get scale()
    {
        return this._scale;
    }

    private set scale(value)
    {
        this._scale = value;

        if (mouseExists())
        {
            this.element.style.transform = `scale(${value})`;
        }
        else
        {
            this.element.style.transform = `scale(${value}) translateX(-50%)`;
        }
    }

    /** The duration of the scale transition. */
    private get scaleDuration()
    {
        return this._scaleDuration;
    }

    private set scaleDuration(value)
    {
        this._scaleDuration = value;

        this.element.style.transitionDuration = `.25s, .25s, .25s, .25s, ${value}ms`;
    }

    /** @description Async handler of the scale setter to await the related transition. */
    private async scaleSetAsync(value: number): Promise<void>
    {
        this.scale = value;

        await sleep(this.scaleDuration);
    }

    /** @description Shows the HTML Element on the end of the scene loading. */
    private sceneLoadedOn(): void
    {
        this.modelLoaded = true;

        if (mouseExists())
        {
            this.elementShow();
        }
    }

    /** @description Shows the HTML Element. */
    public async show(): Promise<void>
    {
        await this.scaleSetAsync(1);
    }
}
