import pointerdownPos     from '@/modules/pointer/scripts/pointerdownPos';
import pointerPosPrevious from '@/modules/pointer/scripts/pointerPosPrevious';
import pointerPos         from '@/modules/pointer/scripts/pointerPos';
import {pointerPosGet}    from '@/modules/pointer/scripts/pointerPosGet';
import mouseExists        from '@/modules/sceneWrapper/scripts/mouseExists';
import {touchEventIs}  from '@/modules/sceneWrapper/scripts/eventRelated';
import SceneWrapper    from '@/modules/sceneWrapper/scripts/SceneWrapper';

/** Updates pointer-related global variables, triggered on any pointer-related event. */
export default function pointerPosUpdate(event: TouchEvent | MouseEvent): void
{
    if (!mouseExists() && event.type.match(/^mouse/))
    {
        return;
    }

    pointerPosPrevious.x = pointerPos.x;
    pointerPosPrevious.y = pointerPos.y;

    const pointerPosCopy = pointerPosGet(event);
    pointerPos.x = pointerPosCopy.x;
    pointerPos.y = pointerPosCopy.y;

    switch (event.type)
    {
        case `touchstart`:
        case `mousedown`:
        {
            pointerdownPos.x = pointerPos.x;
            pointerdownPos.y = pointerPos.y;
            break;
        }
        case `touchend`:
        case `mouseup`:
        {
            pointerPosPrevious.x = pointerPos.x;
            pointerPosPrevious.y = pointerPos.y;
        }
    }

    if (event.cancelable && touchEventIs(event) && (event.target as HTMLElement).closest(`.model-canvas-curtain`))
    {
        event.preventDefault();
    }

    /** Ensure order of the event listeners. */
    window.dispatchEvent(new CustomEvent(`${event.type}1`, {detail: event}));
}
