export default function freezeNested<T>(obj: T): T
{
    Object.freeze(obj);
    if (obj === undefined)
    {
        return obj;
    }

    Object.getOwnPropertyNames(obj).forEach(function (prop)
    {
        if (obj[prop] !== null
            && (typeof obj[prop] === 'object' || typeof obj[prop] === 'function')
            && !Object.isFrozen(obj[prop]))
        {
            freezeNested(obj[prop]);
        }
    });

    return obj;
};
