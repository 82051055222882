/** Object handling the loading of the scene. */
import section from '@/modules/section/scripts/section';
import project from '@/modules/project/scripts/project';
import log     from '@/scripts/log';

export default class Loading
{
    /** HTML Canvas Element used for rendering the scene. */
    private readonly canvas: HTMLCanvasElement;
    /** Circumference of the loading circle. */
    private readonly circumference: number = 48 * 2 * Math.PI;
    /** Timestamp of the construction of the object. */
    private readonly constructedTime: number = Date.now();
    /** Duration of the loading animation delay. */
    private readonly delay: number = 1500;
    /** HTML Element containing the loading circle. */
    private element: HTMLDivElement;
    /** SVG Circle Element of the fill circle. */
    private fillCircle: SVGCircleElement;
    /** Stores the loaded property, don't use directly, use getter/setter without the _ instead.*/
    private _loaded: boolean = false;
    /** Stores the progress property, don't use directly, use getter/setter without the _ instead.*/
    private _progress: number = 0;
    /** Timeout for blocking progress to show the init animation. */
    private readonly progressBlockDuration: number = 500;
    /** Timeout for blocking progress to show the init animation. */
    private readonly progressDuration: number = 3000;
    /** SVG Circle Element of the progress circle. */
    private progressCircle: SVGCircleElement;
    /** The interval of faking of progress of loading assets. */
    private progressFakeInterval: number;
    /** The timout of faking of progress of loading assets. */
    private progressFakeTimeout: number;
    /** Determines how much assets remain to load. */
    private remainingCount: number;
    /** Determines whether the loading circle was shown. */
    private shown: boolean = false;
    /** SVG Circle Element of the start circle. */
    private startCircle: SVGCircleElement;
    /** Interval of the sync method which increases the progress in the loading circle. */
    private syncInterval: number;
    /** HTML Paragraph Element of the text containing the progress percentage. */
    private text: HTMLParagraphElement;
    /** Determines the number of model assets. */
    private totalCount: number = 0;

    constructor(canvas: HTMLCanvasElement)
    {
        this.element = document.querySelector<HTMLDivElement>(`.loading-circle-wrapper`);
        this.fillCircle = this.element.querySelector<SVGCircleElement>(`.fill-circle`);
        this.progressCircle = this.element.querySelector<SVGCircleElement>(`.progress-circle`);
        this.startCircle = this.element.querySelector<SVGCircleElement>(`.start-circle`);
        this.text = this.element.querySelector<HTMLParagraphElement>(`.text`);
        this.canvas = canvas;

        this.startCircle.style.transitionDuration = `${this.progressBlockDuration}ms`;
        this.text.style.transitionDuration = `${this.progressBlockDuration}ms`;

        this.scrollOn = this.scrollOn.bind(this);

        this.scrollOn();

        if (!this.shown)
        {
            window.addEventListener(`scroll`, this.scrollOn);
        }
    }

    /** Determines whether the loading circle is loaded. */
    private get loaded()
    {
        return this._loaded;
    }

    private set loaded(value)
    {
        if (value === this._loaded)
        {
            return;
        }

        this._loaded = value;

        if (value)
        {
            if (this.totalCount)
            {
                this.sync(this.remainingCount, this.totalCount);
            }
            else
            {
                this.progressFake = true;
            }
        }
    }

    /** Current loading progress of the model assets. */
    private get progress(): number
    {
        return this._progress;
    }

    private set progress(value)
    {
        this._progress = value;

        this.element.querySelector(`p`).innerHTML = `${value}%`;

        const offset: number = this.circumference - ((value / 100) * this.circumference);

        this.progressCircle.style.strokeDashoffset = offset.toString();

        if (value === 100)
        {
            this.progressCircle.style.transitionDuration = `${this.progressBlockDuration}ms`;
            this.progressCircle.style.strokeDashoffset = `-${this.circumference}px`;
            this.startCircle.style.strokeDashoffset = `-${this.circumference}px`;
            this.text.style.fontSize = `0`;
            this.startCircle.style.animation = `none`;

            window.setTimeout(() =>
            {
                this.element.style.display = `none`;
                window.dispatchEvent(new CustomEvent(`sceneLoaded`));
            }, this.progressBlockDuration);
        }
    }

    /** @description Sets or clears progress fake related interval and timeout. */
    private set progressFake(value: boolean)
    {
        if (value)
        {
            if (this.progressFakeInterval)
            {
                return;
            }

            /** Triggered on new cycle off the progress fake interval. */
            const progressFakeInterval: () => void = () =>
            {
                if (this.progress >= 99)
                {
                    this.progressFake = false;
                    return;
                }

                this.progress += 1;
            };

            progressFakeInterval();
            this.progressFakeInterval = window.setInterval(progressFakeInterval, 500);
        }
        else
        {
            window.clearInterval(this.progressFakeInterval);
            this.progressFakeInterval = null;
            window.clearTimeout(this.progressFakeTimeout);
            this.progressFakeTimeout = null;
        }
    }

    /** @description Listener of the scroll event. */
    private scrollOn(): void
    {
        if (project.active.name === `violin` && section.active.name === `project-detail`)
        {
            this.show();
            window.removeEventListener(`scroll`, this.scrollOn);
        }
    }

    /** @description Shows the loading circle in case the loading is still ongoing, otherwise, the loading animation is skipped. */
    private show(): void
    {
        this.shown = true;

        if (this.remainingCount === 0)
        {
            window.dispatchEvent(new CustomEvent(`sceneLoaded`));
            return;
        }

        this.element.style.display = `block`;

        /** Delay of the showing of the loading circle, used to prevent buffering caused by loading of the page. */
        const delay: number = Math.max(0, this.delay - (Date.now() - this.constructedTime));

        /** @description Handler of the showing of the loading circle. */
        const show: () => void = () =>
        {
            this.startCircle.style.strokeDashoffset = `0`;
            this.text.style.fontSize = `20px`;

            window.setTimeout(() =>
            {
                this.fillCircle.style.display = `block`;
                this.progressCircle.style.display = `block`;
                this.startCircle.style.animation = `loading-circle-rotate 1000ms infinite ease-in-out`;
                this.loaded = true;
            }, this.progressBlockDuration);
        };

        window.setTimeout(show, delay);
    }

    /** @description Syncs the loading circle with the loaded number of model assets. */
    public sync(remainingCount: number, totalCount: number): void
    {
        if (!this.loaded)
        {
            this.remainingCount = remainingCount;
            this.totalCount = totalCount;
            return;
        }

        window.clearInterval(this.syncInterval);
        this.progressFake = false;
        const progressPrevious: number = this.progress;

        const loadedCount: number = totalCount - remainingCount;
        const progressNew: number = Math.round((loadedCount / totalCount) * 100);

        const progressDiff: number = progressNew - progressPrevious;

        /** Iterator of the progress animation. */
        let i: number = 1;

        this.syncInterval = window.setInterval(() =>
        {
            if (i > progressDiff)
            {
                this.progressFake = true;
                return;
            }

            this.progress = progressPrevious + i;
            i += 1;
        }, this.progressDuration / 100);
    }
}
