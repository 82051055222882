import project        from "./project";
import section        from '@/modules/section/scripts/section';
import resizeDelayAdd from '@/scripts/resizeDelayAdd';

/** The number carousel related to the project carousel used to switch the active project */
export default class Numbers
{
    /** Stores the cloned property, don't use directly, use getter/setter without the _ instead. */
    private _cloned: boolean = false;
    /** The maximal window width when the HTML elements are cloned. */
    private readonly cloneMaxWidth: number = 600;
    /** HTML ELement containing the numbers. */
    private element: HTMLDivElement;
    /** Slides of the number carousel. */
    private slides: HTMLCollectionOf<HTMLDivElement>;

    /** Clears the timeout waiting for the next slide. */
    public clearIntervalTimeout(): void
    {
        [...this.slides].forEach((slide) =>
        {
            slide.classList.remove(`active`);
        });
    }

    /** Determines whether any HTML elements are cloned. */
    private get cloned(): boolean
    {
        return this._cloned;
    }

    private set cloned(cloneValue: boolean)
    {
        if (this.cloned === cloneValue)
        {
            return;
        }

        this._cloned = cloneValue;

        if (cloneValue)
        {
            /** Clones of the carousel slides. */
            const slidesClone: HTMLDivElement[] = [...this.slides].map((slide) =>
            {
                return slide.cloneNode(true) as HTMLDivElement;
            });

            slidesClone.slice(-2).reverse().map((slide) =>
            {
                return slide.cloneNode(true) as HTMLDivElement;
            }).forEach((slide) =>
            {
                slide.classList.add(`clone`);
                this.element.insertBefore(slide, this.element.children[0]);
            });

            slidesClone.slice(0, 2).map((slide) =>
            {
                return slide.cloneNode(true) as HTMLDivElement;
            }).forEach((slide) =>
            {
                slide.classList.add(`clone`);
                this.element.appendChild(slide);
            });
        }
        else
        {
            [...this.slides].filter((element) =>
            {
                return element.classList.contains(`clone`);
            }).forEach((element) =>
            {
                element.remove()
            });
        }

        this.elementUpdate();
    }

    /** Clears the timeout waiting for the next slide. */
    private elementUpdate()
    {
        this.element = document.querySelector(`.numbers`);
        this.slides = this.element.children as HTMLCollectionOf<HTMLDivElement>;
    }

    /** Jumps without the animation from the last to the first slide or vice versa.
     * @param elementIndex The index of the slide the carousel should jump to. */
    public jump(elementIndex: number): void
    {
        this.positionSet({elementIndex, transition: window.innerWidth >= this.cloneMaxWidth});
    }

    /**
     * @description Mounts the number carousel, run only once, required for making the class working, should be run after loading of the DOM tree.
     */
    public mount(): void
    {
        this.elementUpdate();

        [...this.slides].forEach((element, index) =>
        {
            element.dataset.index = index.toString();
        });

        window.addEventListener(`resize`, () =>
        {
            resizeDelayAdd(this.resizeOn.bind(this));
        });

        this.resizeOn();
    }

    /**
     * @description Moves the carousel to the desired position.
     * @param elementIndex The index of the slide the carousel should move to.
     */
    public move(elementIndex: number): void
    {
        this.positionSet({elementIndex});
    }

    /**
     * @description Sets the position of the carousel.
     * @param args
     * @param args.elementIndex The index of the slide the carousel should move to.
     * @param args.transition Determines whether the change of the position should be animated or not.
     */
    private positionSet({elementIndex, transition = true}: { elementIndex: number, transition?: boolean })
    {
        /** The shift of the carousel position caused by the prepended clones. */
        const cloneShift: number = ([...this.slides].filter((slide) =>
        {
            return slide.classList.contains(`clone`)
        }).length / 2);

        /** The new position of the carousel. */
        const positionNew: string = window.innerWidth < this.cloneMaxWidth ? `-${50 * (elementIndex + cloneShift)}vw` : `0px`;

        this.clearIntervalTimeout();

        if (section.active.name === `home`)
        {
            [...this.slides].filter((slide) =>
            {
                return Number.parseInt(slide.dataset.index) === project.active.index && !slide.classList.contains(`clone`);
            }).forEach((slide)=>
            {
                slide.classList.add(`active`);
            });
        }

        if (!transition)
        {
            this.element.classList.remove(`no-jump`);
            this.element.classList.add(`jump`);
        }
        else
        {
            this.element.classList.remove(`jump`);
            this.element.classList.add(`no-jump`);
        }

        this.element.style.transform = `translateX(${positionNew})`;
    }

    /** Listener of the resize event. */
    private resizeOn(): void
    {
        this.cloned = window.innerWidth < this.cloneMaxWidth;
    }
}
