/** Awaits for the specified time.
 * @param duration Time in milliseconds which determines how long will the function await.
 * */
export default async function sleep(duration: number): Promise<void>
{
    return await new Promise((resolve) =>
    {
        window.setTimeout(() =>
        {
            resolve();
        }, duration);
    });
}
