import project from '../../project/scripts/project';
import section from '../../section/scripts/section';
import urlGet  from './urlGet';

/** Updates the URL by the active project and section */
export default function urlUpdate(): void
{
    /** Prevents the update of the URL before the active project and section are determined. */
    if (typeof section.active === `undefined` || typeof project.active === `undefined`)
    {
        return;
    }

    /** The new pathname of the URL. */
    const pathnameNew: string = urlGet();

    if (window.location.pathname !== pathnameNew)
    {
        /** Update the URL, if a browser does not support the History API, pathname will be updated directly.*/
        if (window.history.pushState)
        {
            window.history.pushState({}, document.title, pathnameNew);
        }
        else
        {
            window.location.pathname = pathnameNew;
        }
    }
}
