import {ArcRotateCamera}               from '@babylonjs/core/Cameras/arcRotateCamera';
import {Vector3}  from '@babylonjs/core/Maths/math';
import Axes                            from '@/modules/sceneWrapper/types/Axes';
import pointerPos                      from '@/modules/pointer/scripts/pointerPos';
import NumericRange                    from 'numeric-range';
import {Animation}                     from '@babylonjs/core/Animations/animation';
import {IAnimationKey}                 from '@babylonjs/core/Animations/animationKey';
import {EasingFunction, QuadraticEase} from '@babylonjs/core/Animations/easing';
import section                         from '@/modules/section/scripts/section';
import sleep                           from '@/scripts/sleep';
import SceneWrapper                    from '@/modules/sceneWrapper/scripts/SceneWrapper';
import ModelWrapper                    from '@/modules/sceneWrapper/scripts/ModelWrapper';
import {panDistanceGet, touchEventIs}  from '@/modules/sceneWrapper/scripts/eventRelated';

/** The wrapper of the active camera of the scene. */
export default class CameraWrapper
{
    /** The active camera of the scene. */
    private readonly camera: ArcRotateCamera;
    /** The initial values of the active camera, used for preventing bugs related to animations. */
    private initialValues: Partial<ArcRotateCamera> = {};
    /** Camera zoom value during the start of a pan. */
    private panstartZoomValue: number = 0;
    /** Class manipulating a scene wrapper of any model. */
    private readonly sceneWrapper: SceneWrapper;
    /** The destined target of the camera after all animations are ended. */
    private targetReal: Vector3 = Vector3.Zero();
    /** The target of the camera during the start of a pointer-related event. */
    private targetStartingPoint: Vector3 = Vector3.Zero();
    /** Prevents wheel-triggered scrolling of the zoomed model. */
    private wheelTimeout: number = null;
    /** Duration of the camera zoom animation. */
    private zoomDuration: number = 450;
    /** Stores the zoomValue property, don't use directly, use getter/setter without the _ instead. */
    private _zoomValue: number = 1;
    /** Determines whether the active camera is zooming. */
    public zooming: boolean = false;
    /** Duration of the camera zoom animation. */
    private zoomPointerStartingPos: Pick<Axes, 'x' | 'y'> = {
        x: 0,
        y: 0
    };
    /** Minimal value of the camera zoom. */
    private static readonly zoomValueMin: number = 1;
    /** Maximal value of the camera zoom. */
    private static readonly zoomValueMax: number = 3;

    constructor(sceneWrapper: SceneWrapper)
    {
        this.sceneWrapper = sceneWrapper;
        this.camera = new ArcRotateCamera(`Camera`, Math.PI / 2, Math.PI / 2, 2, Vector3.Zero(), this.sceneWrapper.scene);
        this.camera.inputs.clear();
        this.camera.minZ = -5;

        this.initialValues = {...this.camera};
    }

    /**
     * @description Move the camera on the y axis.
     * @param [timeout=100] The delay of the move to create a pseudo-animation effect.
     */
    public move(timeout: number = 100): void
    {
        /** If the camera is zooming, blocks any moving. */
        if (this.zooming)
        {
            return;
        }

        const canvasHeight: number = Number.parseFloat(getComputedStyle(this.sceneWrapper.canvasCurtain).getPropertyValue(`height`));

        /** Mirrored position of the pointer, mirroring is used for moving upwards when the pointer is moved downwards, imitating the behavior of mobile scrolling. */
        const pointerMirroredPos: number = (pointerPos.y - this.zoomPointerStartingPos.y) * 1.2 * Number(!this.sceneWrapper.panning);

        /** Factor based on the position of the pointer used to calculate the difference of the pointer position. */
        const pointerRelatedFactor: number = pointerMirroredPos / canvasHeight;

        /** Factor based on the camera zoom. */
        const zoomRelatedFactor: number = ModelWrapper.scalingSize * 2 * (this.zoomValue - 1);

        this.targetReal = this.targetStartingPoint.add(new Vector3(0, pointerRelatedFactor * zoomRelatedFactor * 1.5, 0));

        const cameraTarget: Vector3 = this.targetReal.clone();

        const move: () => void = () =>
        {
            /** Repeated check whether the camera is zooming caused by possible delay of the move. */
            if (this.zooming)
            {
                return;
            }

            this.camera.setTarget(cameraTarget);
            this.reset();

            if (this.camera.target.y !== 0 && !this.positionRange.includes(this.camera.target.y))
            {
                this.zoomValue = 1;
                return;
            }
        };

        if (timeout)
        {
            window.setTimeout(move.bind(this), SceneWrapper.interactionDelay);
            return;
        }

        move();
    }

    /** @description Listener of the start of a pan. */
    public panstartOn(): void
    {
        this.panstartZoomValue = this.zoomValue;
    }

    /** @description Listener of a pointer move during a pan. */
    public panmoveOn(event: TouchEvent): void
    {
        /** Distance of the pan touches positions. */
        const panmoveDistance: number = panDistanceGet(event);

        this.zoomValue = this.panstartZoomValue + (panmoveDistance - this.sceneWrapper.panstartDistance) / 100;
    }

    /** @description Listener of the mouseup and the touchend events. */
    public pointerupOn(): void
    {
        if (this.zoomValue === 1.05)
        {
            this.sceneWrapper.scene.stopAnimation(this);
            this.zoomValue = 1;

            if (this.sceneWrapper.cursor)
            {
                this.sceneWrapper.cursor.element.style.transform = `scale(1)`;
            }
        }
    }

    /**
     * @description Range of the position allowed to move with camera, if the bounds are crossed, the camera is unzoomed.
     */
    private get positionRange(): NumericRange
    {
        if (this.zoomValue === 1)
        {
            return new NumericRange(0, 0);
        }

        /** Field of view of the active camera, how much does the camera show. */
        const fov: number = .8 / this.zoomValue;

        return new NumericRange(-1 * (1.2 - fov), 1 - fov);
    }

    /** @description Listener of the mouseup and touchend event. */
    public pointerdownOn(event: TouchEvent | MouseEvent): void
    {
        if (!this.zoomed && !this.zooming && !touchEventIs(event))
        {
            this.zoomValue = 1.05;

            if (this.sceneWrapper.cursor)
            {
                this.sceneWrapper.cursor.element.style.transform = `scale(1.2)`;
            }
        }

        /** Prevent model jumping on pointerdown and pointermove */
        if (!this.zooming)
        {
            this.zoomPointerStartingPos = {...pointerPos};
            this.targetStartingPoint = this.targetReal.clone();
        }
    }

    /** @description Listener of the pointermove event. */
    public pointermoveOn(): void
    {
        if (this.zoomed)
        {
            this.zoomedPointermoveOn();
        }
    }

    /**
     * @description Reset the camera values which are unintentionally altered.
     * */
    private reset(): void
    {
        this.camera.beta = this.initialValues.beta;
        this.camera.radius = this.initialValues.radius;
    }

    /** @description Listener of the wheel event. */
    public wheelOn(event: WheelEvent): void
    {
        if (!section.scrollEnabled)
        {
            /** Prevent sectionIndex scrolling when the camera is zoomed. */
            event.preventDefault();

            if (!this.zoomed)
            {
                section.scrollEnabled = true;
                return;
            }
        }
        else
        {
            return;
        }

        if (this.zoomed && !this.zooming && !this.wheelTimeout)
        {
            const deltaY = CameraWrapper.wheelSpeedNormalize(event as Merge<WheelEvent, { wheelDelta: number }>);

            /** Fake animation for performance reasons. */
            for (let i: number = 1; i < 101; i += SceneWrapper.frameDuration)
            {
                window.setTimeout(() =>
                {
                    this.targetStartingPoint.addInPlace(new Vector3(0, deltaY * .0375 * -1 * (SceneWrapper.frameDuration / 100), 0));
                    this.move();
                }, i);
            }

            this.wheelTimeout = window.setTimeout(() =>
            {
                this.wheelTimeout = null;
            }, 50);
        }
    }

    /** @description Normalizes the delta of the wheel event across different browsers.
     *  */
    private static wheelSpeedNormalize(event: Merge<WheelEvent, { wheelDelta: number }>): number
    {
        let delta: number = 0;
        const {deltaY, wheelDelta} = event;

        // CHROME WIN/MAC | SAFARI 7 MAC | OPERA WIN/MAC | EDGE
        if (wheelDelta)
        {
            delta = -wheelDelta / 120;
        }
        // FIREFOX WIN / MAC | IE
        if (deltaY)
        {
            deltaY > 0 ? delta = 1 : delta = -1;
        }
        return delta;
    }

    /**
     * @description Zoom the camera to the current zoom value.
     * @param zoomValueOld The old value of the camera zoom, used for calculating the difference of zoom-related values.
     */
    private zoom(zoomValueOld: number): void
    {
        /** Height of the HTML Canvas Element. */
        const canvasHeight: number = Number.parseFloat(getComputedStyle(this.sceneWrapper.canvasCurtain).getPropertyValue(`height`));

        /** Position of the double click which triggered the zoom, used for the centering the zoom on to the y value pointed on by the pointer. */
        const pointerRelatedFactor: number = -1 * (((pointerPos.y - this.sceneWrapper.canvasCurtain.getBoundingClientRect().top) / canvasHeight) - .5);

        /** The previous value of the camera target z. */
        const targetZOld: number = this.camera.target.z;
        /** The next value of the camera target z. */
        const targetZNew: number = -1.8 + ((1 / this.zoomValue) * 1.8);
        /** The previous value of the camera target y. */
        const targetYOld: number = this.camera.target.y;

        this.targetStartingPoint.y = this.positionRange.incorporate(this.targetStartingPoint.y + (targetZOld - targetZNew) * .75 * pointerRelatedFactor);
        this.targetStartingPoint.z = targetZNew;
        this.targetReal = this.targetStartingPoint.clone();

        if (this.sceneWrapper.panning)
        {
            this.camera.target.z = targetZNew;
            this.move(0);
        }
        else
        {
            /** The next value of the camera target y. */
            const targetYNew: number = this.targetStartingPoint.y;
            /** Number of frames of the animation. */
            const animationFrameDuration: number = (this.zoomDuration / 1000) * SceneWrapper.fps;

            const targetZAnimation: Animation = new Animation(
                `zAnimation`,
                `target.z`,
                SceneWrapper.fps,
                Animation.ANIMATIONTYPE_FLOAT,
                Animation.ANIMATIONLOOPMODE_RELATIVE
            );

            const targetZKeys: Merge<IAnimationKey, { value: number }>[] = [
                {frame: 0, value: targetZOld},
                {frame: animationFrameDuration, value: targetZNew}
            ];

            targetZAnimation.setKeys(targetZKeys);

            const targetYAnimation: Animation = new Animation(
                `yAnimation`,
                `target.y`,
                SceneWrapper.fps,
                Animation.ANIMATIONTYPE_FLOAT,
                Animation.ANIMATIONLOOPMODE_RELATIVE
            );

            const targetYKeys: Merge<IAnimationKey, { value: number }>[] = [
                {frame: 0, value: targetYOld},
                {frame: animationFrameDuration, value: this.sceneWrapper.animationIntroEnded ? targetYNew : 0}
            ];

            targetYAnimation.setKeys(targetYKeys);

            this.reset();

            const easingFunction: QuadraticEase = new QuadraticEase();

            easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
            targetYAnimation.setEasingFunction(easingFunction);
            targetZAnimation.setEasingFunction(easingFunction);

            this.sceneWrapper.scene.stopAnimation(this.camera);
            this.zooming = true;

            const animatable = this.sceneWrapper.scene.beginDirectAnimation(this.camera, [targetZAnimation, targetYAnimation], 0, animationFrameDuration, false);

            animatable.onAnimationEnd = () =>
            {
                /** Altering the last pointerdown position to prevent jumping of the model triggered by a pointer move during the zooming animation */
                this.zoomPointerStartingPos = {...pointerPos};
                this.targetStartingPoint = this.camera.target.clone();
                this.zooming = false;
            };
        }
    }

    /** @description Determines whether the camera is zoomed. */
    public get zoomed(): boolean
    {
        return this.zoomValue > 1;
    }

    /** @description Listener of pointermove in case of zoomed camera. */
    private zoomedPointermoveOn(): void
    {
        this.move();
    }

    /** @description Current value of zoom of the active camera. */
    public get zoomValue(): number
    {
        return this._zoomValue;
    }

    public set zoomValue(zoomValueNew: number)
    {
        /** The new camera zoom value limited by the minimal and maximal possible value of the camera zoom defined by the class properties. */
        const zoomValueNewReal: number = new NumericRange(CameraWrapper.zoomValueMin, CameraWrapper.zoomValueMax).incorporate(zoomValueNew);

        if (this.zoomValue === zoomValueNewReal)
        {
            return;
        }

        /** The previous value of the camera zoom. */
        const zoomValueOld: number = this.zoomValue;
        this._zoomValue = zoomValueNewReal;

        if (this.zoomed)
        {
            section.scrollEnabled = false;
        }

        this.sceneWrapper.modelWrapper.grabbing = this.zoomed;

        this.zoom(zoomValueOld);
    }

    /** @description Sets the zoom value asynchronously, enables to await the related animation. */
    public async zoomValueSetAsync(value: number): Promise<void>
    {
        this.zoomValue = value;
        await sleep(this.zoomDuration);
    }
}
