import {Color3, Vector3}  from '@babylonjs/core/Maths/math';
import {DirectionalLight} from '@babylonjs/core/Lights/directionalLight';
import SceneWrapper       from '@/modules/sceneWrapper/scripts/SceneWrapper';
import {ShadowGenerator}  from '@babylonjs/core/Lights/Shadows/shadowGenerator';
import {AbstractMesh}     from '@babylonjs/core/Meshes/abstractMesh';
import freezeNested       from '@/scripts/freezeNested';

/** The wrapper of the lights of the scene. */
export default class LightsWrapper
{
    /** Default values of the lights. */
    private readonly defaultValues: DeepReadonly<Partial<DirectionalLight>>[] = freezeNested([
        {
            diffuse: new Color3(1, 1, 1),
            direction: new Vector3(1.234, .549, -1.372),
            intensity: 10,
            name: `whiteLight`,
            radius: .06875
        },
        {
            diffuse: new Color3(.15, .21, .21),
            direction: new Vector3(-1.209, -0.777, 1.322),
            intensity: 10,
            name: `cyanLight`,
            radius: .06875
        },
        {
            diffuse: new Color3(.2, .1, 0),
            direction: new Vector3(13.556, -5.321, -8.416),
            intensity: 5,
            name: `orangeLight`,
            radius: .06875
        }
    ]);
    /** Lights of the scene. */
    private readonly lights: DirectionalLight[] = [];
    /** Class manipulating a scene wrapper of any model. */
    private readonly sceneWrapper: SceneWrapper;
    /** Babylon.js generator of the shadows. */
    private shadowGenerator: ShadowGenerator;

    constructor(sceneWrapper: SceneWrapper)
    {
        this.sceneWrapper = sceneWrapper;

        this.lights = this.defaultValues.map((lightDefaultValues) =>
        {
            const light: DirectionalLight = new DirectionalLight(lightDefaultValues.name, lightDefaultValues.direction, this.sceneWrapper.scene);

            light.diffuse = lightDefaultValues.diffuse;
            light.intensity = lightDefaultValues.intensity;
            light.radius = lightDefaultValues.radius;

            return light;
        });

        this.shadowGenerator = new ShadowGenerator(1024, this.lights[0], true);
        this.shadowGenerator.bias = 0.0000001;
        this.shadowGenerator.normalBias = 0.005;
        this.shadowGenerator.setDarkness(0.25);
        this.shadowGenerator.usePercentageCloserFiltering = true;
    }

    public shadowCreate(mesh: AbstractMesh): void
    {
        this.shadowGenerator.addShadowCaster(mesh, true);
    }
}
