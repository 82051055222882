import iosIs from '@/modules/platforms/scripts/iosIs';

/** @description Fires the resize event listener the second time in case the user's browser lately changes the window size in case of orientation change.
 *  @param resizeOn Listener of the resize event.
 * */
export default function resizeDelayAdd(resizeOn: (event?: UIEvent) => void): void
{
    resizeOn();

    if (iosIs())
    {
        window.setTimeout(() =>
        {
            resizeOn();
        }, 100);

        window.setTimeout(() =>
        {
            resizeOn();
        }, 200);
    }
}
